import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { bs, Image, ImageProps, StoreContext } from './export';

interface Props {
  logo: ImageProps;
  headerItems: HeaderItemProps[];
}

const Header: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(StoreContext);
  const intl = useIntl();

  return (
    <bs.Navbar
      id="main-navbar"
      fixed="top"
      expand="lg"
      variant="light"
      bg="white"
      data-b2x-sticky-external-top-element
      data-b2x-sticky-element-ignore-margin-bottom
    >
      <button
        type="button"
        className="navbar-toggler d-block mr-3"
        onClick={storeContext.sidebarContext.handleSidebarToggle}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <bs.Navbar.Brand className="mr-auto">
        <Link to="/">
          <Image
            src={props.logo.src}
            src2x={props.logo.src2x}
            alt={props.logo.alt}
            title={props.logo.title}
            className="d-inline-block align-top"
          />
        </Link>
      </bs.Navbar.Brand>
      <bs.Navbar.Toggle />
      <bs.Navbar.Collapse>
        <bs.Nav className="ml-auto">
          {storeContext.userContext.loginDto &&
            props.headerItems.map((headerItem, index) => <HeaderItem key={index} eventKey={index} {...headerItem} />)}
          <bs.NavDropdown title={intl.locale} id="locale-dropdown">
            {storeContext.localeContext.supportedLanguages.map((supportedLanguage, index) => (
              <bs.NavDropdown.Item
                disabled={supportedLanguage === intl.locale}
                key={index}
                onClick={() => handleLocaleChange(supportedLanguage)}
              >
                {supportedLanguage}
              </bs.NavDropdown.Item>
            ))}
          </bs.NavDropdown>
          <LinkContainer exact to="/" isActive={() => false}>
            <bs.Nav.Link eventKey={110} onClick={handleLogoutClick} className="pr-0">
              <FormattedMessage id="logout" /> <FontAwesomeIcon icon="sign-out-alt" />
            </bs.Nav.Link>
          </LinkContainer>
        </bs.Nav>
      </bs.Navbar.Collapse>
    </bs.Navbar>
  );

  function handleLogoutClick() {
    storeContext.userContext.handleLogin(undefined);
  }

  function handleLocaleChange(locale: string) {
    storeContext.localeContext.handleLocaleChange(locale);
  }
};

export interface HeaderItemProps {
  id: string;
  icon: IconProp;
  to: string;
  eventKey?: number;
}

function HeaderItem(props: HeaderItemProps) {
  const labelId = 'navbar.' + props.id;
  return (
    <LinkContainer to={props.to} exact={props.to === '/'}>
      <bs.Nav.Link eventKey={props.eventKey}>
        <FontAwesomeIcon icon={props.icon} /> <FormattedMessage tagName="span" id={labelId} />
      </bs.Nav.Link>
    </LinkContainer>
  );
}

export default Header;
