import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosError, AxiosResponse } from 'axios';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { FormattedMessage, useIntl } from 'react-intl';
import * as rest from './Rest';

interface Props {}

const ConventionsUploader: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(b2x.StoreContext);
  const searchContext = React.useContext(b2x.SearchContext);
  const intl = useIntl();

  return (
    <label className="btn btn-success mb-0">
      <FontAwesomeIcon icon="file-upload" /> <FormattedMessage tagName="span" id="conventionsPage.upload" />
      <b2x.bs.Form.Control type="file" onClick={handleClick} onChange={handleUpload} style={{ display: 'none' }} />
    </label>
  );

  /*
    Questa funzione serve per fare in modo scatenare la onChange
    anche se seleziono lo stesso file della selezione precedente.
    (In pratica se seleziono 2 volte lo stesso file)
   */
  function handleClick(e: React.ChangeEvent<any>) {
    const target = e.target as HTMLInputElement;
    target.value = '';
  }

  function handleUpload(e: React.ChangeEvent<any>) {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const fileToUpload = target.files[0] as File;

      const maxSize = 1000000000;

      const data = new FormData();
      data.append('idSelectedStoreGeoArea', storeContext.idStoreGeoArea.toString());
      data.append('maxSize', maxSize.toString());
      data.append('name', fileToUpload.name);
      data.append('file', fileToUpload);

      axios({
        data: data,
        headers: {
          interceptError: false,
        },
        method: 'post',
        url: rest.conventionUpload(),
      })
        .then((response: AxiosResponse) => {
          b2x.Functions.alert(b2x.Functions.t('conventionsPage.upload.success', intl));
          searchContext.handleUploadSuccess();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status === 406) {
            b2x.Functions.alert(
              b2x.Functions.translate(
                'picker.trigger.error.maxSize',
                { actualSize: fileToUpload.size, maxSize: maxSize },
                intl,
              ),
            );
          } else {
            b2x.Functions.alertAxiosError(error);
          }
        });
    }
  }
};

export default ConventionsUploader;
