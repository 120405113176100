import * as React from 'react';
import * as b2x from 'react-b2x/src/export';

interface Props {}

const Sidebar: React.FunctionComponent<Props> = (props) => {
  const sidebarItems: b2x.SidebarItemProps[] = [
    {
      icon: 'tachometer-alt',
      id: 'dashboard',
      to: '/',
    },
    {
      icon: 'star',
      id: 'conventions',
      to: '/conventions',
    },
    {
      icon: 'shopping-cart',
      id: 'products',
      to: '/products',
    },
    {
      icon: 'list-ul',
      id: 'buyingGroups',
      to: '/buying-groups',
    },
    {
      icon: 'bullhorn',
      id: 'announcements',
      to: '/announcements',
    },
    {
      icon: 'chart-pie',
      id: 'surveys',
      to: '/surveys',
    },
    {
      icon: 'wrench',
      id: 'homepageManagement',
      to: '/homepage-management',
    },
    {
      icon: 'images',
      id: 'images',
      to: '/images',
    },
    {
      icon: 'file',
      id: 'files',
      to: '/files',
    },
  ];

  return <b2x.Sidebar items={sidebarItems} />;
};

export default Sidebar;
