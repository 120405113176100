import _DataGrid, { Column as _Column, Paging as _Paging } from 'devextreme-react/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import * as React from 'react';
import { Image, ImageFormat } from './export';
import { ImagesGridRow } from './ImagesGrid';

// tslint:disable:max-classes-per-file
export interface DataGridRowKey {
  id: string;
}

export interface DataGridRowDto<Dto> {
  dto: Dto;
}

export type DataGridRow<Dto> = object & DataGridRowKey & DataGridRowDto<Dto>;

const imageWidth: ImageFormat = 64;
const baseSize = 8;

export class DataGrid extends _DataGrid {
  public static defaultProps: DevExpress.ui.dxDataGridOptions = {
    columnAutoWidth: true,
    columnChooser: { enabled: true, mode: 'select' },
    filterPanel: { visible: true },
    filterRow: { visible: true },
    groupPanel: { visible: true },
    headerFilter: { visible: true, allowSearch: true },
    keyExpr: 'id',
    pager: { visible: true, allowedPageSizes: [5, 10, 25, 50, 100], showPageSizeSelector: true, showInfo: true },
    searchPanel: { visible: true },
    selection: { mode: 'multiple', showCheckBoxesMode: 'always', selectAllMode: 'page' },
    showRowLines: true,
    sorting: { mode: 'multiple' },
    export: {enabled: true, allowExportSelectedData: true, }
  };

  public static Paging = class extends _Paging {
    public static defaultProps = {
      defaultPageSize: 10,
    };
  };

  public static Column = class extends _Column {
    public static defaultProps: DevExpress.ui.dxDataGridColumn = {};

    public static Date = class extends _Column {
      public static defaultProps: DevExpress.ui.dxDataGridColumn = {
        allowGrouping: false,
        dataType: 'date',
        format: {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        } as any,
      };
    };

    public static Price = class extends _Column {
      public static defaultProps: DevExpress.ui.dxDataGridColumn = {
        allowGrouping: false,
        dataType: 'number',
        format: {
          type: 'currency',
          precision: 2,
        },
      };
    };

    public static Image = class extends _Column {
      public static defaultProps: DevExpress.ui.dxDataGridColumn = {
        allowFiltering: false,
        allowGrouping: false,
        allowSorting: false,
        headerCellTemplate: () => '', // Se anche passassi un caption, non lo voglio vedere.
        width: imageWidth + baseSize * 2,
      };

      public static imageTemplate = ({
        value,
        component,
        data,
        key,
        columnIndex,
        column,
        rowIndex,
        rowType,
        row,
      }: {
        value?: string;
        component?: DevExpress.ui.dxDataGrid;
        data: ImagesGridRow;
        key?: string;
        columnIndex?: number;
        column?: DevExpress.ui.dxDataGridColumn;
        rowIndex?: number;
        rowType?: string;
        row?: DevExpress.ui.dxDataGridRowObject;
      }) => {
        return (
          <Image
            adminSrc={value}
            imageFormat={imageWidth}
            previewOnClick
            fluid
            width={data.width}
            height={data.height}
          />
        );
      };
    };
  };
}
