import { Functions, Interfaces } from './export';

export const aesSecreyKey = 'ANTONIOCIUFFAFRASCAPEPPE';

// export const restBaseUrl = (projectConfig: Interfaces.ProjectConfig) =>
//   Functions.buildBaseUrl(projectConfig) + projectConfig.restRoot;

// export const adminRestBaseUrl = (projectConfig: Interfaces.ProjectConfig) =>
//   Functions.buildBaseUrl(projectConfig) + 'rest';

// export const adminRestBaseUrl = () =>
//   Functions.isDevelopmentEnvironment() ? 'http://localhost:8080/AbilitaAdmin/rest' : 'http://admin.dev.b2x.it/rest';

// export const adminRestBaseUrl = () => '/rest';

export const baseSize = 8;

export const screenXSmin = 480;
export const screenSMmin = 768;
export const screenMDmin = 992;
export const screenLGmin = 1200;
export const screenXSmax = screenSMmin - 1;
export const screenSMmax = screenMDmin - 1;
export const screenMDmax = screenLGmin - 1;
