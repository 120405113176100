import { FieldArrayRenderProps } from 'formik';
import * as React from 'react';
import { CreateUpdateCard } from './export';

interface Props<FormValues> {
  items?: FormValues[];
  arrayHelpers: FieldArrayRenderProps;
  objToBePushed?: Partial<FormValues>;
  max?: number;
  render: (item: FormValues, index: number) => React.ReactElement;
}

const FormGroupList = <FormValues extends string | object>(props: React.PropsWithChildren<Props<FormValues>>) => {
  return (
    <div className="content-list">
      {props.items?.map((item, index) => (
        <React.Fragment key={index}>
          <div className="content-list-item">
            {index === 0 && <hr className="mt-0" />}
            <p>
              <strong>
                {index + 1}° elemento {props.max && <small>(di {props.max})</small>}
              </strong>
            </p>
            <CreateUpdateCard.ListControls
              arrayHelpers={props.arrayHelpers}
              index={index}
              length={props.items!.length}
            />
            <div className="mb-n3">
              <div style={{ overflow: 'hidden' }}>{props.render(item, index)}</div>
            </div>
            <hr />
          </div>
        </React.Fragment>
      ))}
      <CreateUpdateCard.ListControls.Add
        arrayHelpers={props.arrayHelpers}
        objToBePushed={props.objToBePushed}
        disabled={props.items?.length === props.max}
      />
    </div>
  );
};

export default FormGroupList;
