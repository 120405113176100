import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bs } from './export';

interface Props {
  name: string;
  defaultSelection?: string[];
  disabled?: boolean;
  onPick: (value?: string) => void;
  render: (pickerContext: PickerContextProps) => React.ReactElement;
  component: React.ReactElement;
}

export interface PickerContextProps {
  show: boolean;
  handleShow: () => void;
  handleClose: () => void;
  selectedIds: string[];
  handleSelectionChanged: (selectedIdsProp: string[]) => void;
  pickedIds: string[];
  handleSubmit: () => void;
  handleUploadSuccess: (uploadedId: string) => void;
  insidePicker: boolean;
}

export const PickerContext = React.createContext<PickerContextProps>({
  handleClose: () => undefined,
  handleSelectionChanged: () => undefined,
  handleShow: () => undefined,
  handleSubmit: () => undefined,
  handleUploadSuccess: () => undefined,
  insidePicker: false,
  pickedIds: [],
  selectedIds: [],
  show: false,
});

const Picker: React.FunctionComponent<Props> = (props) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [pickedIds, setPickedIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    setSelectedIds(props.defaultSelection ?? []);
    setPickedIds(props.defaultSelection ?? []);
  }, [props.defaultSelection]);

  const pickerContext = {
    handleClose: handleClose,
    handleSelectionChanged: handleSelectionChanged,
    handleShow: handleShow,
    handleSubmit: handleSubmit,
    handleUploadSuccess: handleUploadSuccess,
    insidePicker: true,
    pickedIds: pickedIds,
    selectedIds: selectedIds,
    show: show,
  };

  return (
    <PickerContext.Provider value={pickerContext}>
      <div className="mb-3">
        <bs.Button variant="primary" onClick={pickerContext.handleShow} disabled={props.disabled}>
          {pickerContext.pickedIds.length === 0 ? (
            <FormattedMessage id="picker.trigger.pickFromGallery" />
          ) : (
            <FormattedMessage id="picker.trigger.changeFromGallery" />
          )}
        </bs.Button>
        {pickerContext.pickedIds.length > 0 && (
          <>
            <bs.Button variant="danger" className="ml-2" onClick={handleRemove}>
              <FontAwesomeIcon icon="trash" />
            </bs.Button>
          </>
        )}
      </div>
      {props.render(pickerContext)}
      <bs.Modal
        show={pickerContext.show}
        onHide={pickerContext.handleClose}
        animation={false}
        dialogClassName="full-page"
      >
        <bs.Modal.Header closeButton>
          <bs.Modal.Title>
            <FormattedMessage id="picker.trigger.pickFromGallery" />
          </bs.Modal.Title>
        </bs.Modal.Header>
        <bs.Modal.Body>{props.component}</bs.Modal.Body>
        <bs.Modal.Footer>
          <bs.Button variant="light" onClick={pickerContext.handleClose}>
            <FormattedMessage id="cancel" />
          </bs.Button>
          <bs.Button
            variant="primary"
            disabled={pickerContext.selectedIds.length === 0}
            onClick={pickerContext.handleSubmit}
          >
            <FormattedMessage id="picker.trigger.pickFromGallery" />
          </bs.Button>
        </bs.Modal.Footer>
      </bs.Modal>
    </PickerContext.Provider>
  );

  function handleClose() {
    setShow(false);
  }

  function handleShow() {
    setShow(true);
  }

  function handleSelectionChanged(selectedIdsProp: string[]) {
    setSelectedIds(selectedIdsProp);
  }

  function handleUploadSuccess(uploadedId: string) {
    setSelectedIds([uploadedId]);
    setPickedIds([uploadedId]);
    props.onPick(uploadedId);
    handleClose();
  }

  function handleRemove() {
    setPickedIds([]);
    props.onPick(undefined);
  }

  function handleSubmit() {
    setPickedIds(selectedIds);
    props.onPick(selectedIds[0]);
    handleClose();
  }
};

export default Picker;
