import * as React from 'react';
import { CreateUpdateCardContext } from './CreateUpdateCard';
import Message, { MessageProps } from './Message';

export interface Props {
  legend: MessageProps;
  disabled?: boolean;
}

const CreateUpdateCardFieldset: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const createUpdateCardContext = React.useContext(CreateUpdateCardContext);

  return (
    <fieldset disabled={!createUpdateCardContext.editEnabled || props.disabled}>
      <legend>
        <Message {...props.legend} />
      </legend>
      {props.children}
    </fieldset>
  );
};

export default CreateUpdateCardFieldset;
