import axios, { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import * as rest from './Rest';

interface Props {}

interface FormValues {
  code: string;
  title: string;
  typology: string;
  category: string;
  state: string;
  ctOfReference: string;
  zoneName: string;
  provState: string;
  cityName: string;
  zipCode: string;
  addressValue: string;
  latitude: string;
  longitude: string;
  distance: string;
}

const validationSchema = Yup.object().shape({});

const ConventionsFiltersForm: React.FunctionComponent<Props> = (props) => {
  const [categories, setCategories] = React.useState<b2x.Interfaces.AliCategoryDto[]>();
  const intl = useIntl();

  const searchContext = React.useContext(b2x.SearchContext);
  const storeContext = React.useContext(b2x.StoreContext);

  React.useEffect(() => {
    handleSubmit(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    category: '',
    code: '',
    state: '',
    title: '',
    typology: '',
    ctOfReference: '',
    zoneName: '',
    provState: '',
    cityName: '',
    zipCode: '',
    addressValue: '',
    latitude: storeContext.userContext.location?.latitude.toString() ?? '',
    longitude: storeContext.userContext.location?.longitude.toString() ?? '',
    distance: '',
  };

  return (
    <Formik<FormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <b2x.FiltersFormCard>
            <b2x.FormGroup name="code" label={{ formattedMessage: { id: 'conventionsPage.filtersForm.code.label' } }} />
            <b2x.FormGroup
              name="title"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.title.label' } }}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="typology"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.typology.label' } }}
              type="select"
              selectOptions={[
                [
                  b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.PHYSICAL],
                  b2x.Functions.t('conventionsPage.filtersForm.typology.option.PHYSICAL', intl),
                ],
                [
                  b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.WEB],
                  b2x.Functions.t('conventionsPage.filtersForm.typology.option.WEB', intl),
                ],
                [
                  b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.BOTH],
                  b2x.Functions.t('conventionsPage.filtersForm.typology.option.BOTH', intl),
                ],
              ]}
              validationSchema={validationSchema}
              disabled={!storeContext.indexingIsEnabled}
            />
            <b2x.FormGroup
              name="category"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.category.label' } }}
              type="select"
              selectOptions={categories && categories.map((category) => [category.id.toString(), category.name])}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="state"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.state.label' } }}
              type="select"
              selectOptions={[
                [
                  b2x.Interfaces.AliEntityStateEnum[b2x.Interfaces.AliEntityStateEnum.DRAFT],
                  b2x.Functions.t('conventionsPage.filtersForm.state.option.DRAFT', intl),
                ],
                [
                  b2x.Interfaces.AliEntityStateEnum[b2x.Interfaces.AliEntityStateEnum.ONLINE],
                  b2x.Functions.t('conventionsPage.filtersForm.state.option.ONLINE', intl),
                ],
                [
                  b2x.Interfaces.AliEntityStateEnum[b2x.Interfaces.AliEntityStateEnum.OFFLINE],
                  b2x.Functions.t('conventionsPage.filtersForm.state.option.OFFLINE', intl),
                ],
              ]}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="ctOfReference"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.ctOfReference.label' } }}
              type="select"
              selectOptions={[
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.NATIONAL],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.NATIONAL', intl),
                ],
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT1],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.CT1', intl),
                ],
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT2],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.CT2', intl),
                ],
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT3],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.CT3', intl),
                ],
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT4],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.CT4', intl),
                ],
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT5],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.CT5', intl),
                ],
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT6],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.CT6', intl),
                ],
                [
                  b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT7],
                  b2x.Functions.t('conventionsPage.filtersForm.ctOfReference.option.CT7', intl),
                ],
              ]}
              validationSchema={validationSchema}
              disabled={!storeContext.indexingIsEnabled}
            />
            <b2x.FormGroup
              name="zoneName"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.zoneName.label' } }}
              type="select"
              selectOptions={storeContext.cacheContext.cache[intl.locale].italyMappings.stateRegions.map((region) => [
                region.name,
                region.name,
              ])}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="provState"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.provState.label' } }}
              type="select"
              selectOptions={storeContext.cacheContext.cache[
                intl.locale
              ].italyMappings.districtProvinces.map((districtProvince) => [
                districtProvince.name,
                districtProvince.name,
              ])}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="cityName"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.cityName.label' } }}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="zipCode"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.zipCode.label' } }}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="addressValue"
              label={{ formattedMessage: { id: 'conventionsPage.filtersForm.addressValue.label' } }}
              validationSchema={validationSchema}
            />
            <b2x.bs.Row>
              <b2x.bs.Col xs={9}>
                <b2x.bs.Row>
                  <b2x.bs.Col xs={6}>
                    <b2x.FormGroup
                      name="latitude"
                      type="number"
                      label={{ formattedMessage: { id: 'conventionsPage.filtersForm.latitude.label' } }}
                      validationSchema={validationSchema}
                      disabled={!storeContext.indexingIsEnabled}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col xs={6}>
                    <b2x.FormGroup
                      name="longitude"
                      type="number"
                      label={{ formattedMessage: { id: 'conventionsPage.filtersForm.longitude.label' } }}
                      validationSchema={validationSchema}
                      disabled={!storeContext.indexingIsEnabled}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
              </b2x.bs.Col>
              <b2x.bs.Col xs={3}>
                <b2x.FormGroup
                  name="distance"
                  type="select"
                  label={{ formattedMessage: { id: 'conventionsPage.filtersForm.distance.label' } }}
                  selectOptions={[
                    ['1', '1'],
                    ['10', '10'],
                    ['50', '50'],
                    ['100', '100'],
                  ]}
                  validationSchema={validationSchema}
                  disabled={!storeContext.indexingIsEnabled}
                />
              </b2x.bs.Col>
            </b2x.bs.Row>
          </b2x.FiltersFormCard>
        </Form>
      )}
    </Formik>
  );

  function handleSubmit(values: FormValues, formikHelpers?: FormikHelpers<FormValues>) {
    axios({
      method: 'get',
      params: values,
      paramsSerializer: b2x.Functions.filterEmptyParamsSerializer,
      url: rest.conventionSearch(),
    })
      .then((response: AxiosResponse) => {
        const conventions = response.data as b2x.Interfaces.AliConventionDto[];
        searchContext.handleSearch(conventions);
      })
      .finally(() => {
        if (formikHelpers) {
          formikHelpers.setSubmitting(false);
        }
      });
    axios({
      method: 'get',
      url: rest.conventionConfig(),
    }).then((response: AxiosResponse) => {
      const conventionConfig = response.data as b2x.Interfaces.AliConventionConfigDto;
      setCategories(conventionConfig.categories);
    });
  }
};

export default ConventionsFiltersForm;
