import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import FilesPage from './FilesPage';

interface Props {}

const FilesRouter: React.FunctionComponent<Props> = (props) => {

const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact component={FilesPage} />
    </Switch>
  );
};

export default FilesRouter;
