import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArrayRenderProps } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bs } from './export';

export interface Props<FormValues> {
  arrayHelpers: FieldArrayRenderProps;
  objToBePushed?: FormValues;
  disabled?: boolean;
}

const CreateUpdateCardListControlsAdd = <FormValues extends string | object>(
  props: React.PropsWithChildren<Props<FormValues>>,
): React.ReactElement => {
  return (
    <bs.ButtonToolbar>
      <bs.Button
        variant="success"
        type="button"
        onClick={() => props.arrayHelpers.push(props.objToBePushed)}
        disabled={props.disabled}
      >
        <FontAwesomeIcon icon="plus" /> <FormattedMessage tagName="span" id="editForm.list.add" />
      </bs.Button>
    </bs.ButtonToolbar>
  );
};

export default CreateUpdateCardListControlsAdd;
