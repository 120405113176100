import * as React from 'react';
import { bs } from './export';
import Image, { ImageProps } from './Image';
import LoginForm from './LoginForm';
import PageHeader from './PageHeader';

interface Props {
  image: ImageProps;
  restUrl: string;
}

const LoginPage: React.FunctionComponent<Props> = (props) => {
  const [loginModalShow, setLoginModalShow] = React.useState<boolean>(false);

  return (
    <>
      <bs.Row className="justify-content-center">
        <bs.Col sm={6} lg={4}>
          <PageHeader>
            <Image {...props.image} className="d-block mx-auto" fluid />
          </PageHeader>
          <LoginForm restUrl={props.restUrl} />
          {/* <Button variant="primary" onClick={handleLoginModalShow}>
            Launch demo modal plain
          </Button>
          <p>
            <Modal show={loginModalShow} onHide={handleLoginModalClose} animation={false} dialogClassName="plain">
              <Modal.Body>
                <div className="mb-n3">
                  <LoginForm restUrl={props.restUrl} />
                </div>
              </Modal.Body>
            </Modal>
          </p> */}
          {/* <p>
            <ModalExample restUrl={props.restUrl} />
          </p> */}
        </bs.Col>
      </bs.Row>
    </>
  );

  function handleLoginModalShow() {
    setLoginModalShow(true);
  }

  function handleLoginModalClose() {
    setLoginModalShow(false);
  }
};

export default LoginPage;
