import * as React from 'react';
import { useIntl } from 'react-intl';
import { DataGrid, de, Functions, Interfaces, SearchContext, SearchContextProps } from './export';

interface Props {}

enum Col {
  IMAGE = 'image',
  NAME = 'name',
  MIME_TYPE = 'mimeType',
  CREATION_DATE = 'creationDate',
  LAST_UPDATING_DATE = 'lastUpdatingDate',
}

export interface ImagesGridRow extends de.DataGridRow<Interfaces.ImageDto> {
  image: string;
  name: string;
  mimeType: string;
  width: number;
  height: number;
  creationDate: Date;
  lastUpdatingDate: Date;
}

const ImagesGrid: React.FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const searchContext = React.useContext<SearchContextProps<Interfaces.ImageDto>>(SearchContext);

  const dataSource: ImagesGridRow[] = [];

  for (const image of searchContext.dtos) {
    dataSource.push({
      dto: image,
      creationDate: image.creationDate,
      height: image.height,
      id: image.idImage,
      image: image.idImage,
      lastUpdatingDate: image.lastUpdatingDate,
      mimeType: image.mimeType,
      name: image.name,
      width: image.width,
    });
  }

  return (
    <DataGrid dataSource={dataSource} selection={{ mode: 'single' }}>
      <de.DataGrid.Column.Image
        dataField={Col.IMAGE}
        caption={Functions.t('imagesPage.results.image.image', intl)}
        cellRender={de.DataGrid.Column.Image.imageTemplate}
      />
      <de.DataGrid.Column dataField={Col.NAME} caption={Functions.t('imagesPage.results.image.name', intl)} />
      <de.DataGrid.Column dataField={Col.MIME_TYPE} caption={Functions.t('imagesPage.results.image.mimeType', intl)} />
      <de.DataGrid.Column.Date
        dataField={Col.CREATION_DATE}
        caption={Functions.t('imagesPage.results.image.creationDate', intl)}
      />
      <de.DataGrid.Column.Date
        dataField={Col.LAST_UPDATING_DATE}
        caption={Functions.t('imagesPage.results.image.lastUpdatingDate', intl)}
        sortOrder="desc"
      />
    </DataGrid>
  );
};

export default ImagesGrid;
