import * as React from 'react';
import { Image } from './export';
import ImagesPage from './ImagesPage';
import Picker, { PickerContextProps } from './Picker';

interface Props {
  name: string;
  defaultSelection?: string;
  disabled?: boolean;
  onPick: (value?: string) => void;
}

const ImagePicker: React.FunctionComponent<Props> = (props) => {
  return (
    <Picker
      defaultSelection={props.defaultSelection ? [props.defaultSelection] : []}
      onPick={props.onPick}
      name={props.name}
      disabled={props.disabled}
      component={<ImagesPage />}
      render={(pickerContext: PickerContextProps) => (
        <>
          {pickerContext.pickedIds.map((id) => (
            <Image key={id} adminSrc={id} imageFormat={400} fluid thumbnail />
          ))}
        </>
      )}
    />
  );
};

export default ImagePicker;
