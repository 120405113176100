import axios, { AxiosResponse } from 'axios';
import { Field, FieldArray, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import qs from 'qs';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { StoreContext } from 'react-b2x/src/export';

interface Props {
  content: b2x.Interfaces.ContentDto;
}

interface FormValues extends b2x.Interfaces.ContentDto {}

const HomePageManagementForm: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(StoreContext);

  return (
    <Formik<FormValues> initialValues={props.content} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <b2x.CreateUpdateCard<b2x.Interfaces.ContentDto, FormValues> dto={props.content} formErrors={formik.errors} >
            <b2x.CreateUpdateCard.Fieldset legend={{ message: props.content.contentType.name }}>
              {formik.values.contentValueList.contentValues.map(
                (contentSectionValueDto: b2x.Interfaces.ContentSectionValueDto, index) =>
                  buildContentSection(
                    contentSectionValueDto,
                    index,
                    `contentValueList.contentValues.${index}`,
                    props.content.contentType.elements.contentSections[index],
                    formik,
                  ),
              )}
            </b2x.CreateUpdateCard.Fieldset>
          </b2x.CreateUpdateCard>
        </Form>
      )}
    </Formik>
  );

  function buildContentSection(
    contentSectionValueDto: b2x.Interfaces.ContentSectionValueDto,
    index1: number,
    name: string,
    contentSectionDto: b2x.Interfaces.ContentSectionDto,
    formik: FormikProps<FormValues>,
  ) {
    return (
      <React.Fragment key={index1}>
        {contentSectionValueDto.type.toString() ===
        b2x.Interfaces.ContentSectionType[b2x.Interfaces.ContentSectionType.LIST] ? (
          <FieldArray
            name={name + '.contentSectionValueList'}
            render={(arrayHelpers) => (
              <>
                <b2x.FormGroup
                  type="empty"
                  label={{
                    message: `${contentSectionValueDto.name} (${contentSectionValueDto.contentSectionValueList
                      ?.length ?? 0}/${contentSectionDto.maxValue})`,
                  }}
                  name={name + '.contentSectionValueList'}
                  help={{ message: contentSectionDto.instructions }}
                />
                <b2x.FormGroupList<b2x.Interfaces.ContentSectionValueList>
                  items={contentSectionValueDto.contentSectionValueList}
                  arrayHelpers={arrayHelpers}
                  objToBePushed={buildContentSectionValueListFromContentSectionDto(contentSectionDto)}
                  max={contentSectionDto.maxValue}
                  render={(contentSectionValueList: b2x.Interfaces.ContentSectionValueList, index2) => (
                    <>
                      {contentSectionValueList.contentValues.map((subContentSectionValueDto, index3) =>
                        buildContentSection(
                          subContentSectionValueDto,
                          index3,
                          name + `.contentSectionValueList.${index2}.contentValues.${index3}`,
                          contentSectionDto.contentSections[index3],
                          formik,
                        ),
                      )}
                    </>
                  )}
                />
              </>
            )}
          />
        ) : (
          <Field name={name + '.value'} validate={contentSectionDto.required ? requiredValidation : undefined}>
            {(fieldProps: FieldProps) => (
              <b2x.FormGroup
                label={{ message: contentSectionValueDto.name }}
                name={name + '.value'}
                type={contentSectionValueDto.type.toString() === 'IMAGE' ? 'image-picker' : 'text'}
                help={contentSectionDto.instructions ? { message: contentSectionDto.instructions } : undefined}
              />
            )}
          </Field>
        )}
      </React.Fragment>
    );
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    axios({
      data: qs.stringify({ content: JSON.stringify(values), idSelectedStoreGeoArea: storeContext.idStoreGeoArea }),
      method: 'put',
      url: b2x.Rest.contentUpdate(),
    })
      .then((response: AxiosResponse) => {
        window.console.log('ok');
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }

  function buildContentSectionValueListFromContentSectionDto(
    contentSectionDto: b2x.Interfaces.ContentSectionDto,
  ): b2x.Interfaces.ContentSectionValueList {
    return {
      contentValues: contentSectionDto.contentSections.map((contentSection) => ({
        dynamic: contentSection.dynamic,
        id: 0,
        idSection: contentSection.id,
        instructions: contentSection.instructions,
        name: contentSection.name,
        required: contentSection.required,
        type: contentSection.type,
        value: contentSection.defaultValue,
      })),
      id: 0,
    };
  }

  function requiredValidation(value: string) {
    let error;
    if (!value) {
      error = 'Required';
    }
    return error;
  }
};

export default HomePageManagementForm;
