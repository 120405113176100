import { SearchContextProps } from './export';

export interface ProjectConfig {
  idStoreGeoArea: number;
  name: string;
  restRoot: string;
}

export enum localStorageKey {
  PAGE_SIZE = 'pageSize',
}

export interface FormattedMessageProps {
  id: string;
  values?: Record<string, any>;
}

export interface ResultsSelectionActionProps<Dto> {
  labelId: string;
  min?: number;
  max?: number;
  action?: (searchContext: SearchContextProps<Dto>) => void;
  disabledCheck?: (searchContext: SearchContextProps<Dto>) => string | undefined;
  children?: ResultsSelectionActionProps<Dto>[];
}

export interface CommonInfo {
  id: number;
}

export interface UserBaseDto extends CommonInfo {
  name: string;
  surname: string;
  enabled: boolean;
  idImage?: string;
  nickname: string;
  login: string;
}

export interface UserInfoDto extends UserBaseDto {
  email: string;
  creationDate: Date;
  canLoginFlag: boolean;
}

export interface UserDto extends UserInfoDto {
  middleName?: string;
}

export interface LoginDto {
  sessionId: string;
  userDto: UserDto;
}

export enum ImageType {
  UNKNOWN,
  COMPANY_IMAGE,
  FLAG_IMAGE,
}

export interface ImageDto {
  idImage: string;
  digest?: string;
  IdCompany?: number;
  idStoreGeoArea?: number;
  width: number;
  height: number;
  name: string;
  mimeType: string;
  imageType: ImageType;
  path?: string;
  description?: string;
  note?: string;
  creationDate: Date;
  idCreationUser: number;
  idImageState: number;
  lastUpdatingDate: Date;
}

export enum FileType {
  UNKNOWN,
  COMPANY_FILE,
  FLAG_FILE,
}

export interface FileDto {
  idFile: string;
  IdCompany?: number;
  idStoreGeoArea?: number;
  name: string;
  mimeType: string;
  fileType: FileType;
  path?: string;
  description?: string;
  note?: string;
  creationDate: Date;
  idCreationUser: number;
  idFileState: number;
  idCustomer?: number;
  idStoreOrder?: number;
}

export enum AliEntityTypeEnum {
  CONVENTION,
  PRODUCT,
  COUPON,
}

export enum AliEntityStateEnum {
  ONLINE,
  OFFLINE,
  DRAFT,
}

export enum AliEntityContractTypeEnum {
  CONTRACT,
  CONVENTION,
}

export enum AliEntityVisuraCervedEnum {
  YES,
  NO,
  UNAVAILABLE,
}

export enum AliEntityControl231Enum {
  YES,
  NO,
  UNAVAILABLE,
}

export enum AliEntityControlGdprEnum {
  YES,
  NO,
  UNAVAILABLE,
}

export enum AliEntityCtOfReferenceEnum {
  NATIONAL,
  CT1,
  CT2,
  CT3,
  CT4,
  CT5,
  CT6,
  CT7,
}

export enum AliEntityInReviewEnum {
  PRICELIST_UPDATE,
  DISCOUNT_CODE_UPDATE,
  VARIOUS_CONDITIONS_UPDATE,
  EXTINCTION,
}

export interface AliCategoryDto extends CommonInfo {
  code: string;
  name: string;
}

export interface AliConventionConfigDto {
  categories?: AliCategoryDto[];
}

export interface StateRegionDto extends CommonInfo {
  country: CountryInfoDto;
  name: string;
  code: string;
}

export interface CountryInfoDto extends CommonInfo {
  name: string;
  codeIso3: string;
  codeIso2: string;
  codeCurrency: string;
}

export interface DistrictProvinceDto extends CommonInfo {
  name: string;
  code: string;
  state: StateRegionDto;
}

export interface AliEntityDto extends CommonInfo {
  code: string;
  title: string;
  highlights: boolean;
  image?: string;
  description?: string;
  additionalDescription1?: string;
  additionalDescription2?: string;
  additionalDescription3?: string;
  discountPercentage: number;
  category: AliCategoryDto;
  startDate?: Date;
  endDate?: Date;
  state: AliEntityStateEnum;
  type: AliEntityTypeEnum;
  addresses?: AddressDto[];
  callToAction?: AliEntityCallToActionDto;
  membersAttachments?: AliEntityAttachmentDto[];
  internalAttachments?: AliEntityAttachmentDto[];
  creationDate: Date;
  lastUpdatingDate: Date;
  visuraCerved?: AliEntityVisuraCervedEnum;
  businessName?: string;
  fiscalCode?: string;
  email?: string;
  commercialReferent?: string;
  aliReferent?: string;
  referentContact?: string;
  firstAgreementDate?: Date;
  contractType?: AliEntityContractTypeEnum;
  control231?: AliEntityControl231Enum;
  controlGdpr?: AliEntityControlGdprEnum;
  ctOfReference?: AliEntityCtOfReferenceEnum;
  inReview?: AliEntityInReviewEnum;
}

export interface AliConventionDto extends AliEntityDto {
  typology: ALI_ConventionTypologyEnum;
}

export enum ALI_ConventionTypologyEnum {
  PHYSICAL,
  WEB,
  BOTH,
}

export interface AliEntityAttachmentDto {
  id: string;
  label?: string;
}

export interface AliEntityCallToActionDto {
  label?: string;
  action?: string;
  image?: string;
}

export interface AddressBaseDto extends CommonInfo {
  countryCode?: string;
  idCountry?: number;
  cityName?: string;
}

export interface AddressDto extends AddressBaseDto {
  zoneName?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  provState?: string;
  zipCode?: string;
  civicCode?: string;
  latitude?: number;
  longitude?: number;
  tags?: string[];
}

export enum AliAddressTagsEnum {
  SEDE_OPERATIVA,
  SEDE_LEGALE,
}

export interface NtsArticleBaseDto extends CommonInfo {
  code: string;
  title: string;
  category: NtsCategoryDto;
  creationDate: Date;
  lastUpdatingDate: Date;
}

export interface NtsArticleDto extends NtsArticleBaseDto {
  subtitle: string;
  image: string;
  description: string;
}

export interface NtsCategoryDto extends CommonInfo {
  code: string;
  name: string;
}

export interface NtsArticleConfigDto {
  categories: NtsCategoryDto[];
}

export interface ContentDto extends ContentInfoDto {
  idCompanyDivision?: number;
  idStoreGeoArea: number;
  contentValueList: ContentSectionValueList;
  idCustomer: number;
  translationVersion: number;
  storeCategoryPath?: string;
  contentAddresses?: ContentAddressDto;
}

export interface ContentInfoDto extends ContentBaseDto {
  contentType: ContentTypeDto;
  state: ContentState;
  codeLanguage: string;
  languageCodes?: TranslateDto[];
  lastUpdatingDate: Date;
  creationDate: Date;
  idCreationUser: number;
  htmlFriendlyUrl?: string;
  htmlTitle?: string;
  htmlDescription?: string;
  endDate?: Date;
  startDate?: Date;
  interruptedFlag: boolean;
  temporizedFlag: boolean;
}

export interface ContentBaseDto extends CommonInfo {
  code: string;
  branch: string;
  idOriginalContent: number;
  deletedFlag: boolean;
}

export interface ContentSectionValueList {
  contentValues: ContentSectionValueDto[];
  id: number;
  translationDate?: Date;
  translationVersion?: number;
}

export interface ContentSectionValueDto extends CommonInfo {
  idSection: number;
  type: ContentSectionType;
  name: string;
  value?: string;
  styleName?: string;
  translationDate?: Date;
  translationVersion?: number;
  contentSectionValueList?: ContentSectionValueList[];
  instructions?: string;
  required?: boolean;
  dynamic?: boolean;
}

export enum ContentSectionType {
  TEXT,
  TEXT_BOX,
  TEXT_AREA,
  LIST,
  LINK,
  NUMBER,
  SELECT,
  BOOLEAN,
  IMAGE,
  FILE,
  STOREPRODUCT,
  STORECATEGORY,
  STOREORDER,
  CUSTOMER,
  DATE,
}

export interface ContentAddressDto extends AddressDto {
  defaultFlag: boolean;
}
export enum ContentState {
  WORKING,
  READY,
  OFFLINE,
  DELETED,
  DEPRECATED,
}
export interface TranslateDto {
  translateId: string;
  codeLanguage: string;
  translationDate: Date;
  translationVersion: number;
  versionDate: Date;
}

export interface ContentTypeDto extends ContentTypeInfoDto {
  idCompanyDivision?: number;
  idStoreGeoArea?: number;
  elements: ContentSectionList;
}

export interface ContentTypeInfoDto extends ContentTypeBaseDto {
  lastUpdatingDate: Date;
  creationDate: Date;
}

export interface ContentTypeBaseDto extends CommonInfo {
  code: string;
  name: string;
  branch: string;
  idOriginalContentType: number;
  deletedFlag: boolean;
}

export interface ContentSectionList {
  contentSections: ContentSectionDto[];
}

export interface ContentSectionDto extends CommonInfo {
  name: string;
  type: ContentSectionType;
  required?: boolean;
  dynamic?: boolean;
  withStyle: boolean;
  elasticsearchFlag: boolean;
  minValue?: number;
  maxValue?: number;
  instructions?: string;
  preferredValue?: string;
  defaultValue?: string;
  contentSections: ContentSectionDto[];
}

export interface Cache {
  [locale: string]: CacheDto;
}

export interface CacheDto {
  italyMappings: CountryMappingsDto;
}

export interface CountryMappingsDto {
  stateRegions: StateRegionDto[];
  districtProvinces: DistrictProvinceDto[];
  stateRegionId_stateRegionMap: { [key: number]: StateRegionDto };
  stateRegionName_stateRegionMap: { [key: string]: StateRegionDto };
  districtProvinceId_districtProvinceMap: { [key: number]: DistrictProvinceDto };
  districtProvinceName_districtProvinceMap: { [key: string]: DistrictProvinceDto };
  stateRegionId_districtProvincesMap: { [key: number]: DistrictProvinceDto[] };
  stateRegionName_districtProvincesMap: { [key: string]: DistrictProvinceDto[] };
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface AnnouncementDto extends CommonInfo {
  type: AnnouncementTypeEnum;
  customer: CustomerBaseDto;
  code: string;
  categories?: AnnouncementCategoryDto[];
  title?: string;
  shortDescription?: string;
  details?: string;
  terms?: string;
  price?: number;
  city?: string;
  name?: string;
  email?: string;
  phone?: string;
  phoneSecondary?: string;
  images?: AnnouncementImageDto[];
  datePublication: Date;
  dateStart: Date;
  dateEnd: Date;
  state: AnnouncementStateEnum;
  creationDate: Date;
  lastUpdatingDate: Date;
}

export enum AnnouncementTypeEnum {
  LOOK_FOR,
  OFFER,
  GIVE,
}

export interface AnnouncementCategoryDto extends CommonInfo {
  code: string;
  description: string;
}

export interface AnnouncementImageDto {
  priority: number;
  path: string;
}

export enum AnnouncementStateEnum {
  DRAFT,
  APPROVED,
  REJECTED,
  EXPIRED,
  DELETED,
}

export interface AnnouncementConfigDto {
  categories: AnnouncementCategoryDto[];
  customers: CustomerBaseDto[];
}

export interface CustomerBaseDto extends CommonInfo {
  name?: string;
  surname?: string;
  businessName?: string;
  image?: string;
  sex?: SexType;
  email?: string;
  username: string;
  codeDefaultLanguage: string;
  verifiedFlag: boolean;
  externalId?: string;
  idStoreGeoArea: number;
  idSuperStore: number;
  taxCode?: string;
}

export enum SexType {
  M = 'M',
  F = 'F',
  O = 'O',
}
