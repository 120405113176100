import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ConventionPage from './ConventionPage';
import ConventionsPage from './ConventionsPage';

interface Props {}

const ConventionsRouter: React.FunctionComponent<Props> = (props) => {

  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact component={ConventionsPage} />
      <Route path={`${match.path}/new`} exact component={ConventionPage} />
      <Route path={`${match.path}/:conventionId`} exact component={ConventionPage} />
    </Switch>
  );
};

export default ConventionsRouter;
