import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import ConventionForm from './ConventionForm';
import * as rest from './Rest';

interface Props {}

interface PathParams {
  conventionId?: string;
}

const ConventionPage: React.FunctionComponent<Props> = (props) => {
  const [convention, setConvention] = React.useState<b2x.Interfaces.AliConventionDto>();
  const [categories, setCategories] = React.useState<b2x.Interfaces.AliCategoryDto[]>();

  const { conventionId } = useParams<PathParams>();

  React.useEffect(() => {
    if (conventionId) {
      axios({
        method: 'get',
        url: rest.conventionGet(conventionId),
      }).then((response: AxiosResponse) => {
        setConvention(response.data as b2x.Interfaces.AliConventionDto);
      });
    }
    axios({
      method: 'get',
      url: rest.conventionConfig(),
    }).then((response: AxiosResponse) => {
      const conventionConfig = response.data as b2x.Interfaces.AliConventionConfigDto;
      setCategories(conventionConfig.categories);
    });
  }, [conventionId]);

  return (
    <>
      <b2x.PageHeader>
        {conventionId ? (
          convention && <FormattedMessage id="conventionPage.title.update" values={{ code: convention.code }} />
        ) : (
          <FormattedMessage id="conventionPage.title.create" />
        )}
      </b2x.PageHeader>
      {conventionId
        ? convention && categories && <ConventionForm convention={convention} categories={categories} />
        : categories && <ConventionForm categories={categories} />}
    </>
  );
};

export default ConventionPage;
