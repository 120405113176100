import * as React from 'react';
import { bs, Rest, StoreContext } from './export';

export type ImageFormat = 32 | 64 | 144 | 288 | 400 | 800 | 1600;

interface Props {
  src?: string;
  src2x?: string;
  adminSrc?: string;
  adminSrc2x?: string;
  title?: string;
  imageFormat?: ImageFormat;
  previewOnClick?: boolean;
  centerBlock?: boolean;
  className?: string;
  fluid?: boolean;
  thumbnail?: boolean;
  width?: number;
  height?: number;
  alt?: string;
}

const Image: React.FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [previewShow, setPreviewShow] = React.useState<boolean>(false);

  const storeContext = React.useContext(StoreContext);

  let pictureClassName = '';
  if (props.previewOnClick) {
    pictureClassName = pictureClassName + ' preview';
  }
  if (loading) {
    pictureClassName = pictureClassName + ' loading';
  }
  let imageClassName = props.className;
  if (props.centerBlock) {
    imageClassName = imageClassName + ' center-block';
  }
  let placeholderClassName = 'placeholder';
  if (props.className) {
    placeholderClassName = placeholderClassName + ' ' + props.className;
  }
  if (props.centerBlock) {
    placeholderClassName = placeholderClassName + ' center-block';
  }
  let finalWidth = props.width;
  let finalHeight = props.height;
  if (props.imageFormat && props.width && props.height) {
    finalWidth = props.imageFormat < props.width ? props.imageFormat : props.width;
    finalHeight = props.imageFormat < props.height ? props.imageFormat : props.height;
  }
  return (
    <picture className={pictureClassName}>
      <bs.Image
        className={imageClassName}
        src={getSrc()}
        srcSet={getSrcSet()}
        onClick={handlePreviewShow}
        onLoad={handleLoad}
        width={finalWidth}
        height={finalHeight}
        title={props.title}
        fluid={props.fluid}
        thumbnail={props.thumbnail}
        alt={props.alt}
      />
      {loading && <span className={placeholderClassName} style={{ width: finalWidth, height: finalHeight }} />}
      {props.previewOnClick && (
        <bs.Modal size="xl" show={previewShow} onHide={handlePreviewClose} animation={false} dialogClassName="plain">
          <bs.Modal.Body>
            <Image {...props} fluid centerBlock previewOnClick={false} />
          </bs.Modal.Body>
        </bs.Modal>
      )}
    </picture>
  );

  function getSrc(forcedImageFormat?: ImageFormat) {
    let src = props.src;
    if (props.adminSrc) {
      src = Rest.imageGet(props.adminSrc);
      if (props.imageFormat || forcedImageFormat) {
        src = src + '?minImageFormat=' + (forcedImageFormat ?? props.imageFormat);
      }
    }
    return src;
  }

  function getSrc2x() {
    let src2x = props.src2x;
    if (props.adminSrc2x) {
      src2x = Rest.imageGet(props.adminSrc2x);
    }
    return src2x;
  }

  function getSrcSet() {
    let srcSet;
    if (getSrc2x()) {
      srcSet = `${getSrc2x()} 2x, ${getSrc()} 1x`;
    }
    return srcSet;
  }

  function handlePreviewShow() {
    if (props.previewOnClick) {
      setPreviewShow(true);
    }
  }

  function handlePreviewClose() {
    setPreviewShow(false);
  }

  function handleLoad() {
    setLoading(false);
  }
};

export type ImageProps = Props;
export default Image;
