import axios, { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import * as rest from './Rest';

interface Props {}

interface FormValues {
  code: string;
  type: string;
  title: string;
  // shortDescription: string;
  // details: string;
  // terms: string;
  // city: string;
  // name: string;
  // email: string;
  // phone: string;
  // phoneSecondary: string;
  customer: string;
  category: string;
  state: string;
}

const validationSchema = Yup.object().shape({});

const AnnouncementsFiltersForm: React.FunctionComponent<Props> = (props) => {
  const [categories, setCategories] = React.useState<b2x.Interfaces.AnnouncementCategoryDto[]>();
  const intl = useIntl();

  const searchContext = React.useContext(b2x.SearchContext);
  const storeContext = React.useContext(b2x.StoreContext);

  React.useEffect(() => {
    handleSubmit(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    code: '',
    type: '',
    title: '',
    // shortDescription: '',
    // details: '',
    // terms: '',
    // city: '',
    // name: '',
    // email: '',
    // phone: '',
    // phoneSecondary: '',
    customer: '',
    category: '',
    state: '',
  };

  return (
    <Formik<FormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <b2x.FiltersFormCard>
            <b2x.FormGroup
              name="code"
              label={{ formattedMessage: { id: 'announcementsPage.filtersForm.code.label' } }}
            />
            <b2x.FormGroup
              name="type"
              label={{ formattedMessage: { id: 'announcementsPage.filtersForm.type.label' } }}
              type="select"
              selectOptions={[
                [
                  b2x.Interfaces.AnnouncementTypeEnum[b2x.Interfaces.AnnouncementTypeEnum.OFFER],
                  b2x.Functions.t('announcementsPage.filtersForm.type.option.OFFER', intl),
                ],
                [
                  b2x.Interfaces.AnnouncementTypeEnum[b2x.Interfaces.AnnouncementTypeEnum.LOOK_FOR],
                  b2x.Functions.t('announcementsPage.filtersForm.type.option.LOOK_FOR', intl),
                ],
                [
                  b2x.Interfaces.AnnouncementTypeEnum[b2x.Interfaces.AnnouncementTypeEnum.GIVE],
                  b2x.Functions.t('announcementsPage.filtersForm.type.option.GIVE', intl),
                ],
              ]}
              validationSchema={validationSchema}
              disabled={!storeContext.indexingIsEnabled}
            />
            <b2x.FormGroup
              name="title"
              label={{ formattedMessage: { id: 'announcementsPage.filtersForm.title.label' } }}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="customer"
              label={{ formattedMessage: { id: 'announcementsPage.filtersForm.customer.label' } }}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="category"
              label={{ formattedMessage: { id: 'announcementsPage.filtersForm.category.label' } }}
              type="select"
              selectOptions={categories?.map((category) => [category.id.toString(), category.description])}
              validationSchema={validationSchema}
            />
            <b2x.FormGroup
              name="state"
              label={{ formattedMessage: { id: 'announcementsPage.filtersForm.state.label' } }}
              type="select"
              selectOptions={[
                [
                  b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.DRAFT],
                  b2x.Functions.t('announcementsPage.filtersForm.state.option.DRAFT', intl),
                ],
                [
                  b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.APPROVED],
                  b2x.Functions.t('announcementsPage.filtersForm.state.option.APPROVED', intl),
                ],
                [
                  b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.REJECTED],
                  b2x.Functions.t('announcementsPage.filtersForm.state.option.REJECTED', intl),
                ],
                [
                  b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.EXPIRED],
                  b2x.Functions.t('announcementsPage.filtersForm.state.option.EXPIRED', intl),
                ],
                [
                  b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.DELETED],
                  b2x.Functions.t('announcementsPage.filtersForm.state.option.DELETED', intl),
                ],
              ]}
              validationSchema={validationSchema}
            />
          </b2x.FiltersFormCard>
        </Form>
      )}
    </Formik>
  );

  function handleSubmit(values: FormValues, formikHelpers?: FormikHelpers<FormValues>) {
    axios({
      method: 'get',
      params: values,
      paramsSerializer: b2x.Functions.filterEmptyParamsSerializer,
      url: rest.announcementSearch(),
    })
      .then((response: AxiosResponse) => {
        const announcements = response.data as b2x.Interfaces.AnnouncementDto[];
        searchContext.handleSearch(announcements);
      })
      .finally(() => {
        if (formikHelpers) {
          formikHelpers.setSubmitting(false);
        }
      });
    axios({
      method: 'get',
      url: rest.announcementConfig(),
    }).then((response: AxiosResponse) => {
      const announcementConfig = response.data as b2x.Interfaces.AnnouncementConfigDto;
      setCategories(announcementConfig.categories);
    });
  }
};

export default AnnouncementsFiltersForm;
