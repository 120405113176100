import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import * as rest from './Rest';
interface Props {}

interface PathParams {
  announcementId?: string;
}

const AnnouncementPage: React.FunctionComponent<Props> = (props) => {
  const [announcement, setAnnouncement] = React.useState<b2x.Interfaces.AnnouncementDto>();

  const intl = useIntl();
  const { announcementId } = useParams<PathParams>();
  const history = useHistory();

  React.useEffect(() => {
    if (announcementId) {
      axios({
        method: 'get',
        url: rest.announcementGet(announcementId),
      }).then((response: AxiosResponse) => {
        setAnnouncement(response.data as b2x.Interfaces.AnnouncementDto);
      });
    }
  }, [announcementId]);

  return (
    <>
      {announcement && (
        <b2x.bs.Card>
          <b2x.bs.Card.Header>
            <b2x.bs.ButtonToolbar>
              <b2x.bs.Button
                className="mr-2"
                variant="danger"
                type="button"
                onClick={reject}
                disabled={
                  announcement.state.toString() !==
                  b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.DRAFT]
                }
              >
                <FormattedMessage id="announcementsPage.results.selectionActions.reject.label" />
              </b2x.bs.Button>
              <b2x.bs.Button
                variant="success"
                type="button"
                onClick={approve}
                disabled={
                  announcement.state.toString() !==
                  b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.DRAFT]
                }
              >
                <FormattedMessage id="announcementsPage.results.selectionActions.approve.label" />
              </b2x.bs.Button>
            </b2x.bs.ButtonToolbar>
          </b2x.bs.Card.Header>
          <b2x.bs.Card.Body>
            <h5 className="clearfix">
              <b2x.bs.Badge variant="secondary" className="float-left">
                <FormattedMessage id={'announcementPage.editForm.type.option.' + announcement.type} />
              </b2x.bs.Badge>
              <b2x.bs.Badge variant="secondary" className="float-left ml-2">
                {announcement.categories![0].description}
              </b2x.bs.Badge>
              <b2x.bs.Badge
                variant={b2x.Functions.getAnnouncementStateVariant(announcement.state)}
                className="float-left ml-2"
              >
                <FormattedMessage id={'announcementPage.editForm.state.option.' + announcement.state} />
              </b2x.bs.Badge>
            </h5>
            <div className="mb-n4">
              <div className="mb-4">
                <b2x.bs.Card.Title as="h3">{announcement.title}</b2x.bs.Card.Title>
                <p>{announcement.shortDescription}</p>
                {announcement.price && (
                  <p className="text-success font-weight-bold">
                    {b2x.Functions.formatCurrency(announcement.price, 'EUR', intl.locale)}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <h5>
                  <FormattedMessage id="announcementPage.editForm.details.label" />
                </h5>
                <p>{announcement.details}</p>
              </div>
              <div className="mb-4">
                <h5>
                  <FormattedMessage id="announcementPage.editForm.terms.label" />
                </h5>
                <p>{announcement.terms}</p>
              </div>
              {announcement.images && (
                <div className="mb-4">
                  <h5>
                    <FormattedMessage id="announcementPage.editForm.images.label" />
                  </h5>
                  <b2x.bs.Row className="mb-n2">
                    {announcement.images.map((image, index) => (
                      <b2x.bs.Col sm="2" key={index}>
                        <p>
                          <b2x.Image adminSrc={image.path} thumbnail previewOnClick />
                        </p>
                      </b2x.bs.Col>
                    ))}
                  </b2x.bs.Row>
                </div>
              )}
              <div className="mb-4">
                <h5>
                  <FormattedMessage id="announcementPage.contactInfo" />
                </h5>
                <b2x.bs.Table size="sm" bordered hover style={{ width: 'auto' }}>
                  <tbody>
                    <tr>
                      <th>
                        <FormattedMessage id="announcementPage.editForm.name.label" />
                      </th>
                      <td>{announcement.name}</td>
                    </tr>
                    <tr>
                      <th>
                        <FormattedMessage id="announcementPage.editForm.email.label" />
                      </th>
                      <td>{announcement.email}</td>
                    </tr>
                    <tr>
                      <th>
                        <FormattedMessage id="announcementPage.editForm.phone.label" />
                      </th>
                      <td>{announcement.phone}</td>
                    </tr>
                    <tr>
                      <th>
                        <FormattedMessage id="announcementPage.editForm.phoneSecondary.label" />
                      </th>
                      <td>{announcement.phoneSecondary}</td>
                    </tr>
                  </tbody>
                </b2x.bs.Table>
              </div>
              <div className="mb-4">
                <h5>
                  <FormattedMessage id="announcementPage.editForm.customer.label" />
                </h5>
                <b2x.bs.Table size="sm" bordered hover style={{ width: 'auto' }}>
                  <tbody>
                    <tr>
                      <th>
                        <FormattedMessage id="customer.name" />
                      </th>
                      <td>{announcement.customer.name}</td>
                    </tr>
                    <tr>
                      <th>
                        <FormattedMessage id="customer.surname" />
                      </th>
                      <td>{announcement.customer.surname}</td>
                    </tr>
                    <tr>
                      <th>
                        <FormattedMessage id="customer.sex" />
                      </th>
                      <td>{announcement.customer.sex}</td>
                    </tr>
                    <tr>
                      <th>
                        <FormattedMessage id="customer.email" />
                      </th>
                      <td>{announcement.customer.username}</td>
                    </tr>
                  </tbody>
                </b2x.bs.Table>
              </div>
            </div>
          </b2x.bs.Card.Body>
          <b2x.bs.Card.Footer className="text-muted">
            <small>
              {announcement.lastUpdatingDate !== announcement.creationDate ? (
                <FormattedMessage
                  id="creationAndLastUpdatingDateAndTime"
                  values={{
                    creationDate: b2x.Functions.formatDateFriendly(announcement.creationDate),
                    creationTime: b2x.Functions.formatDateOnlyTime(announcement.creationDate),
                    lastUpdatingDate: b2x.Functions.formatDateFriendly(announcement.lastUpdatingDate),
                    lastUpdatingTime: b2x.Functions.formatDateOnlyTime(announcement.lastUpdatingDate),
                    // ...b2x.Functions.intlHtmlTagsFormatter,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="creationDateAndTime"
                  values={{
                    date: b2x.Functions.formatDateFriendly(announcement.creationDate),
                    time: b2x.Functions.formatDateOnlyTime(announcement.creationDate),
                    // ...b2x.Functions.intlHtmlTagsFormatter,
                  }}
                />
              )}
              {announcement.lastUpdatingDate !== announcement.creationDate && <></>}
            </small>
          </b2x.bs.Card.Footer>
        </b2x.bs.Card>
      )}
    </>
  );

  function approve() {
    b2x.Functions.showConfirm({
      message: b2x.Functions.translate(
        'announcementsPage.results.selectionActions.approve.confirm',
        { selected: 1 },
        intl,
      ),
    }).then(() => {
      axios({
        method: 'PUT',
        data: [announcement?.id],
        url: rest.announcementApprove(),
      }).then(() => {
        history.goBack();
      });
    });
  }

  function reject() {
    b2x.Functions.showConfirm({
      message: b2x.Functions.translate(
        'announcementsPage.results.selectionActions.reject.confirm',
        { selected: 1 },
        intl,
      ),
    }).then(() => {
      axios({
        method: 'PUT',
        data: [announcement?.id],
        url: rest.announcementReject(),
      }).then(() => {
        history.goBack();
      });
    });
  }
};

export default AnnouncementPage;
