import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { useIntl } from 'react-intl';

interface Props {}

const Header: React.FunctionComponent<Props> = (props) => {
  const headerItems: b2x.HeaderItemProps[] = [];
  const intl = useIntl();

  return (
    <b2x.Header
      logo={{
        alt: b2x.Functions.t('title', intl),
        src: require('./images/logo.png'),
        src2x: require('./images/logo@2x.png'),
        title: b2x.Functions.t('title', intl),
      }}
      headerItems={headerItems}
    />
  );
};

export default Header;
