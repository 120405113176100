import axios, { AxiosResponse } from 'axios';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { StoreContext } from 'react-b2x/src/export';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as rest from './Rest';

interface Props {
  convention?: b2x.Interfaces.AliConventionDto; 
  categories: b2x.Interfaces.AliCategoryDto[];
}

interface FormValues {
  id: string;
  category: {
    id: string;
  };
  code: string;
  title: string;
  highlights: boolean;
  image: string;
  description: string;
  additionalDescription1: string;
  additionalDescription2: string;
  additionalDescription3: string;
  discountPercentage: number;
  startDate: string;
  endDate: string;
  state: string;
  typology: string;
  addresses: AddressFormValues[];
  callToAction: {
    label: string;
    image: string;
    action: string;
  };
  membersAttachments: AttachmentFormValue[];
  internalAttachments: AttachmentFormValue[];
  visuraCerved: string;
  businessName: string;
  fiscalCode: string;
  email: string;
  commercialReferent: string;
  aliReferent: string;
  referentContact: string;
  firstAgreementDate: string;
  contractType: string;
  control231: string;
  controlGdpr: string;
  ctOfReference: string;
  inReview: string;
}

interface AddressFormValues {
  id: string;
  address1: string;
  address2: string;
  address3: string;
  civicCode: string;
  zoneName: string;
  cityName: string;
  provState: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  tags: string[];
}

interface AttachmentFormValue {
  id: string;
  label: string;
}

const validationSchema = Yup.object().shape({
  category: Yup.object().shape({
    id: Yup.string().required(),
  }),
  discountPercentage: Yup.number().lessThan(100).moreThan(-1),
  highlights: Yup.string().oneOf(['true', 'false', '']),
  state: Yup.string().required(),
  title: Yup.string().required().max(50),
  typology: Yup.string().required(),
});

const ConventionForm: React.FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const storeContext = React.useContext(StoreContext);

  return (
    <Formik<FormValues>
      initialValues={{
        additionalDescription1: props.convention?.additionalDescription1 ?? '',
        additionalDescription2: props.convention?.additionalDescription2 ?? '',
        additionalDescription3: props.convention?.additionalDescription3 ?? '',
        addresses:
          props.convention?.addresses?.map((address) => ({
            address1: address.address1 ?? '',
            address2: address.address2 ?? '',
            address3: address.address3 ?? '',
            cityName: address.cityName ?? '',
            civicCode: address.civicCode ?? '',
            id: address?.id.toString() ?? '',
            latitude: address.latitude?.toString() ?? '',
            longitude: address.longitude?.toString() ?? '',
            provState: address.provState ?? '',
            tags: address.tags ?? [],
            zipCode: address.zipCode ?? '',
            zoneName: address.zoneName ?? '',
          })) ?? [],
        aliReferent: props.convention?.aliReferent ?? '',
        businessName: props.convention?.businessName ?? '',
        callToAction: {
          action: props.convention?.callToAction?.action ?? '',
          image: props.convention?.callToAction?.image ?? '',
          label: props.convention?.callToAction?.label ?? '',
        },
        category: {
          id: props.convention?.category?.id.toString() ?? '',
        },
        code: props.convention?.code ?? '',
        commercialReferent: props.convention?.commercialReferent ?? '',
        contractType: props.convention?.contractType?.toString() ?? '',
        control231: props.convention?.control231?.toString() ?? '',
        controlGdpr: props.convention?.controlGdpr?.toString() ?? '',
        ctOfReference: props.convention?.ctOfReference?.toString() ?? '',
        description: props.convention?.description ?? '',
        discountPercentage: props.convention?.discountPercentage ?? 0,
        email: props.convention?.email ?? '',
        endDate: b2x.Functions.formatDateForInput(props.convention?.endDate),
        firstAgreementDate: b2x.Functions.formatDateForInput(props.convention?.firstAgreementDate),
        fiscalCode: props.convention?.fiscalCode ?? '',
        highlights: props.convention?.highlights ?? false,
        id: props.convention?.id?.toString() ?? '',
        image: props.convention?.image ?? '',
        inReview: props.convention?.inReview?.toString() ?? '',
        internalAttachments:
          props.convention?.internalAttachments?.map((internalAttachment) => ({
            id: internalAttachment.id,
            label: internalAttachment.label ?? '',
          })) ?? [],
        membersAttachments:
          props.convention?.membersAttachments?.map((membersAttachment) => ({
            id: membersAttachment.id,
            label: membersAttachment.label ?? '',
          })) ?? [],
        referentContact: props.convention?.referentContact ?? '',
        startDate: b2x.Functions.formatDateForInput(props.convention?.startDate),
        state: props.convention?.state?.toString() ?? '',
        title: props.convention?.title ?? '',
        typology: props.convention?.typology?.toString() ?? '',
        visuraCerved: props.convention?.visuraCerved?.toString() ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <div className="mb-n3">
            <b2x.CreateUpdateCard<b2x.Interfaces.AliConventionDto, FormValues>
              dto={props.convention}
              formErrors={formik.errors}
            >
              <b2x.CreateUpdateCard.Fieldset
                legend={{ formattedMessage: { id: 'conventionPage.editForm.fieldset.data.legend' } }}
              >
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.code.label' } }}
                      name="code"
                      validationSchema={validationSchema}
                      disabled
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={6}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.title.label' } }}
                      name="title"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.highlights.label' } }}
                      name="highlights"
                      type="checkbox"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.image.label' } }}
                      name="image"
                      type="image-picker"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={9}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.description.label' } }}
                      name="description"
                      type="wysiwyg"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
                <b2x.bs.Row>
                  <b2x.bs.Col sm={4}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.additionalDescription1.label' } }}
                      name="additionalDescription1"
                      type="wysiwyg"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={4}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.additionalDescription2.label' } }}
                      name="additionalDescription2"
                      type="wysiwyg"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={4}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.additionalDescription3.label' } }}
                      name="additionalDescription3"
                      type="wysiwyg"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.discountPercentage.label' } }}
                      name="discountPercentage"
                      type="number"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.category.label' } }}
                      name="category.id"
                      type="select"
                      selectOptions={props.categories.map(
                        (category) => [category.id.toString(), category.name] as [string, string],
                      )}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.startDate.label' } }}
                      name="startDate"
                      type="date"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.endDate.label' } }}
                      name="endDate"
                      type="date"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.state.label' } }}
                      name="state"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.AliEntityStateEnum[b2x.Interfaces.AliEntityStateEnum.DRAFT],
                          b2x.Functions.t('conventionPage.editForm.state.option.DRAFT', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityStateEnum[b2x.Interfaces.AliEntityStateEnum.ONLINE],
                          b2x.Functions.t('conventionPage.editForm.state.option.ONLINE', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityStateEnum[b2x.Interfaces.AliEntityStateEnum.OFFLINE],
                          b2x.Functions.t('conventionPage.editForm.state.option.OFFLINE', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.typology.label' } }}
                      name="typology"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.PHYSICAL],
                          b2x.Functions.t('conventionPage.editForm.typology.option.PHYSICAL', intl),
                        ],
                        [
                          b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.WEB],
                          b2x.Functions.t('conventionPage.editForm.typology.option.WEB', intl),
                        ],
                        [
                          b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.BOTH],
                          b2x.Functions.t('conventionPage.editForm.typology.option.BOTH', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
              </b2x.CreateUpdateCard.Fieldset>
              <b2x.CreateUpdateCard.Fieldset
                legend={{ formattedMessage: { id: 'conventionPage.editForm.fieldset.geolocation.legend' } }}
                disabled={
                  formik.values.typology !==
                    b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.PHYSICAL] &&
                  formik.values.typology !==
                    b2x.Interfaces.ALI_ConventionTypologyEnum[b2x.Interfaces.ALI_ConventionTypologyEnum.BOTH]
                }
              >
                <FieldArray
                  name="addresses"
                  render={(arrayHelpers) => (
                    <b2x.FormGroupList<AddressFormValues>
                      max={7}
                      items={formik.values.addresses}
                      arrayHelpers={arrayHelpers}
                      objToBePushed={{
                        tags: [b2x.Interfaces.AliAddressTagsEnum[b2x.Interfaces.AliAddressTagsEnum.SEDE_OPERATIVA]],
                      }}
                      render={(address, index) => (
                        <>
                          <b2x.FormGroup
                            name={`addresses.${index}.id`}
                            type="hidden"
                            validationSchema={validationSchema}
                          />
                          <b2x.bs.Row>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.address1.label' } }}
                                name={`addresses.${index}.address1`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.civicCode.label' } }}
                                name={`addresses.${index}.civicCode`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.address2.label' } }}
                                name={`addresses.${index}.address2`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.address3.label' } }}
                                name={`addresses.${index}.address3`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                          </b2x.bs.Row>
                          <b2x.bs.Row>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.zoneName.label' } }}
                                name={`addresses.${index}.zoneName`}
                                type="select"
                                selectOptions={storeContext.cacheContext.cache[
                                  intl.locale
                                ].italyMappings.stateRegions.map((region) => [region.name, region.name])}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.provState.label' } }}
                                name={`addresses.${index}.provState`}
                                type="select"
                                selectOptions={
                                  address &&
                                  storeContext.cacheContext.cache[intl.locale].italyMappings
                                    .stateRegionName_districtProvincesMap[address.zoneName] &&
                                  storeContext.cacheContext.cache[
                                    intl.locale
                                  ].italyMappings.stateRegionName_districtProvincesMap[
                                    address.zoneName
                                  ].map((province) => [province.name, province.name])
                                }
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.cityName.label' } }}
                                name={`addresses.${index}.cityName`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.zipCode.label' } }}
                                name={`addresses.${index}.zipCode`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                          </b2x.bs.Row>
                          <b2x.bs.Row>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.latitude.label' } }}
                                name={`addresses.${index}.latitude`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                            <b2x.bs.Col sm={3}>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.longitude.label' } }}
                                name={`addresses.${index}.longitude`}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                          </b2x.bs.Row>
                          <b2x.bs.Row>
                            <b2x.bs.Col>
                              <b2x.FormGroup
                                label={{ formattedMessage: { id: 'address.tags.label' } }}
                                name={`addresses.${index}.tags`}
                                type="tags"
                                tags={[
                                  [
                                    b2x.Interfaces.AliAddressTagsEnum[b2x.Interfaces.AliAddressTagsEnum.SEDE_OPERATIVA],
                                    b2x.Functions.t('address.tags.sedeOperativa.label', intl),
                                  ],
                                  [
                                    b2x.Interfaces.AliAddressTagsEnum[b2x.Interfaces.AliAddressTagsEnum.SEDE_LEGALE],
                                    b2x.Functions.t('address.tags.sedeLegale.label', intl),
                                  ],
                                ]}
                                validationSchema={validationSchema}
                              />
                            </b2x.bs.Col>
                          </b2x.bs.Row>
                        </>
                      )}
                    />
                  )}
                />
              </b2x.CreateUpdateCard.Fieldset>
              <b2x.CreateUpdateCard.Fieldset
                legend={{ formattedMessage: { id: 'conventionPage.editForm.fieldset.cta.legend' } }}
              >
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.ctaLabel.label' } }}
                      name="callToAction.label"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.ctaImage.label' } }}
                      name="callToAction.image"
                      type="image-picker"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.ctaValue.label' } }}
                      name="callToAction.action"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
              </b2x.CreateUpdateCard.Fieldset>
              <b2x.CreateUpdateCard.Fieldset
                legend={{ formattedMessage: { id: 'conventionPage.editForm.fieldset.membersAttachments.legend' } }}
              >
                <FieldArray
                  name="membersAttachments"
                  render={(arrayHelpers) => (
                    <b2x.FormGroupList<AttachmentFormValue>
                      items={formik.values.membersAttachments}
                      arrayHelpers={arrayHelpers}
                      render={(membersAttachment, index) => (
                        <b2x.bs.Row>
                          <b2x.bs.Col sm={3}>
                            <b2x.FormGroup
                              label={{
                                formattedMessage: {
                                  id: 'conventionPage.editForm.membersAttachment.id.label',
                                },
                              }}
                              name={`membersAttachments.${index}.id`}
                              type="file-picker"
                              validationSchema={validationSchema}
                            />
                          </b2x.bs.Col>
                          <b2x.bs.Col sm={3}>
                            <b2x.FormGroup
                              label={{
                                formattedMessage: {
                                  id: 'conventionPage.editForm.membersAttachment.label.label',
                                },
                              }}
                              name={`membersAttachments.${index}.label`}
                              help={{formattedMessage: {id: 'conventionPage.editForm.membersAttachment.label.help'}}}
                              validationSchema={validationSchema}
                            />
                          </b2x.bs.Col>
                        </b2x.bs.Row>
                      )}
                    />
                  )}
                />
              </b2x.CreateUpdateCard.Fieldset>
              <b2x.CreateUpdateCard.Fieldset
                legend={{ formattedMessage: { id: 'conventionPage.editForm.fieldset.internalAttachments.legend' } }}
              >
                <FieldArray
                  name="internalAttachments"
                  render={(arrayHelpers) => (
                    <b2x.FormGroupList<AttachmentFormValue>
                      items={formik.values.internalAttachments}
                      arrayHelpers={arrayHelpers}
                      render={(internalAttachment, index) => (
                        <b2x.bs.Row>
                          <b2x.bs.Col sm={3}>
                            <b2x.FormGroup
                              label={{
                                formattedMessage: {
                                  id: 'conventionPage.editForm.internalAttachment.id.label',
                                },
                              }}
                              name={`internalAttachments.${index}.id`}
                              type="file-picker"
                              validationSchema={validationSchema}
                            />
                          </b2x.bs.Col>
                          <b2x.bs.Col sm={3}>
                            <b2x.FormGroup
                              label={{
                                formattedMessage: {
                                  id: 'conventionPage.editForm.internalAttachment.label.label',
                                },
                              }}
                              name={`internalAttachments.${index}.label`}
                              validationSchema={validationSchema}
                            />
                          </b2x.bs.Col>
                        </b2x.bs.Row>
                      )}
                    />
                  )}
                />
              </b2x.CreateUpdateCard.Fieldset>
              <b2x.CreateUpdateCard.Fieldset
                legend={{ formattedMessage: { id: 'conventionPage.editForm.fieldset.internalData.legend' } }}
              >
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.visuraCerved.label' } }}
                      name="visuraCerved"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.AliEntityVisuraCervedEnum[b2x.Interfaces.AliEntityVisuraCervedEnum.YES],
                          b2x.Functions.t('conventionPage.editForm.visuraCerved.option.YES', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityVisuraCervedEnum[b2x.Interfaces.AliEntityVisuraCervedEnum.NO],
                          b2x.Functions.t('conventionPage.editForm.visuraCerved.option.NO', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityVisuraCervedEnum[
                            b2x.Interfaces.AliEntityVisuraCervedEnum.UNAVAILABLE
                          ],
                          b2x.Functions.t('conventionPage.editForm.visuraCerved.option.UNAVAILABLE', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.businessName.label' } }}
                      name="businessName"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.fiscalCode.label' } }}
                      name="fiscalCode"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.email.label' } }}
                      name="email"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
                <b2x.bs.Row>
                  <b2x.bs.Col sm={4}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.commercialReferent.label' } }}
                      name="commercialReferent"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={4}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.aliReferent.label' } }}
                      name="aliReferent"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={4}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.referentContact.label' } }}
                      name="referentContact"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.firstAgreementDate.label' } }}
                      name="firstAgreementDate"
                      type="date"
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.contractType.label' } }}
                      name="contractType"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.AliEntityContractTypeEnum[b2x.Interfaces.AliEntityContractTypeEnum.CONTRACT],
                          b2x.Functions.t('conventionPage.editForm.contractType.option.CONTRACT', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityContractTypeEnum[b2x.Interfaces.AliEntityContractTypeEnum.CONVENTION],
                          b2x.Functions.t('conventionPage.editForm.contractType.option.CONVENTION', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.control231.label' } }}
                      name="control231"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.AliEntityControl231Enum[b2x.Interfaces.AliEntityControl231Enum.YES],
                          b2x.Functions.t('conventionPage.editForm.control231.option.YES', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityControl231Enum[b2x.Interfaces.AliEntityControl231Enum.NO],
                          b2x.Functions.t('conventionPage.editForm.control231.option.NO', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityControl231Enum[b2x.Interfaces.AliEntityControl231Enum.UNAVAILABLE],
                          b2x.Functions.t('conventionPage.editForm.control231.option.UNAVAILABLE', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.controlGdpr.label' } }}
                      name="controlGdpr"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.AliEntityControlGdprEnum[b2x.Interfaces.AliEntityControlGdprEnum.YES],
                          b2x.Functions.t('conventionPage.editForm.controlGdpr.option.YES', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityControlGdprEnum[b2x.Interfaces.AliEntityControlGdprEnum.NO],
                          b2x.Functions.t('conventionPage.editForm.controlGdpr.option.NO', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityControlGdprEnum[b2x.Interfaces.AliEntityControlGdprEnum.UNAVAILABLE],
                          b2x.Functions.t('conventionPage.editForm.controlGdpr.option.UNAVAILABLE', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
                <b2x.bs.Row>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.ctOfReference.label' } }}
                      name="ctOfReference"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.NATIONAL],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.NATIONAL', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT1],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.CT1', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT2],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.CT2', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT3],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.CT3', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT4],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.CT4', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT5],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.CT5', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT6],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.CT6', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityCtOfReferenceEnum[b2x.Interfaces.AliEntityCtOfReferenceEnum.CT7],
                          b2x.Functions.t('conventionPage.editForm.ctOfReference.option.CT7', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                  <b2x.bs.Col sm={3}>
                    <b2x.FormGroup
                      label={{ formattedMessage: { id: 'conventionPage.editForm.inReview.label' } }}
                      name="inReview"
                      type="select"
                      selectOptions={[
                        [
                          b2x.Interfaces.AliEntityInReviewEnum[b2x.Interfaces.AliEntityInReviewEnum.PRICELIST_UPDATE],
                          b2x.Functions.t('conventionPage.editForm.inReview.option.PRICELIST_UPDATE', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityInReviewEnum[
                            b2x.Interfaces.AliEntityInReviewEnum.DISCOUNT_CODE_UPDATE
                          ],
                          b2x.Functions.t('conventionPage.editForm.inReview.option.DISCOUNT_CODE_UPDATE', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityInReviewEnum[
                            b2x.Interfaces.AliEntityInReviewEnum.VARIOUS_CONDITIONS_UPDATE
                          ],
                          b2x.Functions.t('conventionPage.editForm.inReview.option.VARIOUS_CONDITIONS_UPDATE', intl),
                        ],
                        [
                          b2x.Interfaces.AliEntityInReviewEnum[b2x.Interfaces.AliEntityInReviewEnum.EXTINCTION],
                          b2x.Functions.t('conventionPage.editForm.inReview.option.EXTINCTION', intl),
                        ],
                      ]}
                      validationSchema={validationSchema}
                    />
                  </b2x.bs.Col>
                </b2x.bs.Row>
              </b2x.CreateUpdateCard.Fieldset>
            </b2x.CreateUpdateCard>
          </div>
        </Form>
      )}
    </Formik>
  );

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    axios({
      data: values,
      method: 'post',
      url: rest.conventionCreateUpdate(),
    })
      .then((response: AxiosResponse) => {
        history.goBack();
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }
};

export default ConventionForm;
