import { AxiosError } from 'axios';
import { FormikErrors } from 'formik';
import Moment from 'moment';
import qs from 'qs';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { bs, Interfaces } from './export';

export function t(id: string, intl: IntlShape): string {
  return translate(id, {}, intl);
}

export function translate(id: string, values: {}, intl: IntlShape): string {
  return intl.formatMessage({ id: id }, values);
}

export function formatDate(date: Date, format: string): string {
  return Moment(date).format(format);
}

export function formatDateForInput(date?: Date): string {
  if (date) {
    return formatDate(date, 'YYYY-MM-DD');
  } else {
    return '';
  }
}

export function formatDateFriendly(date: Date) {
  return formatDate(date, 'dddd DD MMMM YYYY');
}

export function formatDateOnlyTime(date: Date) {
  return formatDate(date, 'hh:mm');
}

export function hasTest(fieldName: string, mixedSchema: Yup.MixedSchema, hasActiveTest: string): boolean {
  const schemaDescription = mixedSchema.describe();
  const fields = schemaDescription.fields as any;
  const fieldNameSplitted = fieldName.split('.').join('.fields.').split('.');
  const field = getNestedObject(fields, fieldNameSplitted);
  if (field !== undefined) {
    for (const test of field.tests) {
      if (test.name === hasActiveTest) {
        return true;
      }
    }
  }
  return false;
}

export function isDevelopmentEnvironment(): boolean {
  return process.env.NODE_ENV === 'development';
}

// export function buildBaseUrl(projectConfig: Interfaces.ProjectConfig) {
//   return isWithContextPath(projectConfig) ? '/' + projectConfig.name + '/' : '/';
// }

// export function isWithContextPath(projectConfig: Interfaces.ProjectConfig): boolean {
//   const pathname = window.location.pathname;
//   const split = pathname.split('/');
//   const contextPath = split[1];
//   return contextPath === projectConfig.name;
// }

export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isObjectEmpty(obj: object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function alertAxiosError(error: AxiosError) {
  if (error.response) {
    alert('ERROR ' + error.response.status + '\n\n' + error.message + '\n\n' + error.response.data);
  } else {
    alert(error.message);
  }
}

export function filterEmptyParamsSerializer(params: object) {
  return qs.stringify(params, {
    filter: (prefix, value) => {
      if (value === '') {
        return;
      }
      return value;
    },
  });
}

export function printErrors(obj: object) {
  return (
    <ul className="mb-0">
      {Object.keys(obj).map((value: string, index: number) => {
        return printErrorsInternal(obj, value, index);
      })}
    </ul>
  );
}
function printErrorsInternal(obj: object, value: string, index: number) {
  const errorMessage = (obj as any)[value];
  if (errorMessage instanceof Object) {
    return (
      <li key={index}>
        {value}: {printErrors(errorMessage)}
      </li>
    );
  } else {
    return <li key={index}>{errorMessage}</li>;
  }
}

export function isFormValid(errors: FormikErrors<any>) {
  return Object.keys(errors).length === 0;
}

export function logChangedProps(prevProps: any, nextProps: any) {
  Object.keys(nextProps)
    .filter((key) => {
      return nextProps[key] !== prevProps[key];
    })
    .map((key) => {
      window.console.log('changed property:', key, 'from', prevProps[key], 'to', nextProps[key]);
    });
  return false;
}

export function joinArrayItemsSpecificField(
  array: any[],
  fieldName: string,
  separator: string = ', ',
  func?: (item: any) => void,
) {
  return (
    array
      // dall'oggetto prendo solo campo che mi serve
      .map((item) => item[fieldName])
      // non ricordo, ma serve
      .filter((fieldValue) => fieldValue)
      // filtro i doppioni
      .filter((value, index, innerArray) => innerArray.indexOf(value) === index)
      // lavoro il singolo item
      .map((fieldValue) => (func ? func(fieldValue) : fieldValue))
      // unisco tutto
      .join(separator)
  );
}

export function getNestedObject(object: any, path: string[]) {
  return path.reduce(
    (accumulator, currentValue) =>
      accumulator && accumulator[currentValue] !== 'undefined' ? accumulator[currentValue] : undefined,
    object,
  );
}

export function showModal(props: {
  title?: string;
  message?: string;
  closable: boolean;
  buttons: {
    label: string;
    variant: 'primary' | 'light';
    doResolve?: boolean;
    doReject?: boolean;
  }[];
}): Promise<void> {
  const promise = new Promise<void>((resolve, reject) => {
    const handleResolve = () => {
      resolve();
    };
    const handleReject = () => {
      reject();
    };
    const wrapper = document.body.appendChild(document.createElement('div'));
    function Modal(modalProps: {
      title?: string;
      message?: string;
      closable: boolean;
      buttons: {
        label: string;
        variant: 'primary' | 'light';
        doResolve?: boolean;
        doReject?: boolean;
      }[];
      onResolve: () => void;
      onReject: () => void;
    }) {
      const [show, setShow] = React.useState<boolean>(true);
      const innerHandleResolve = () => {
        handleClose();
        modalProps.onResolve();
      };
      const innerHandleReject = () => {
        handleClose();
        modalProps.onReject();
      };
      function handleClose() {
        setShow(false);
      }
      function handleExited() {
        ReactDOM.unmountComponentAtNode(wrapper);
        wrapper.remove();
      }
      return (
        <bs.Modal
          show={show}
          backdrop={modalProps.closable ? true : 'static'}
          onHide={handleClose}
          onExited={handleExited}
        >
          {(modalProps.title || modalProps.closable) && (
            <bs.Modal.Header closeButton={modalProps.closable}>
              {modalProps.title && <bs.Modal.Title as="h5">{modalProps.title}</bs.Modal.Title>}
            </bs.Modal.Header>
          )}
          {modalProps.message && (
            <bs.Modal.Body>
              <p>{modalProps.message}</p>
            </bs.Modal.Body>
          )}
          <bs.Modal.Footer>
            {modalProps.buttons.map((button, index) => (
              <bs.Button
                key={index}
                variant={button.variant}
                onClick={button.doResolve ? innerHandleResolve : button.doReject ? innerHandleReject : handleClose}
              >
                {button.label}
              </bs.Button>
            ))}
          </bs.Modal.Footer>
        </bs.Modal>
      );
    }
    ReactDOM.render(
      React.createElement(Modal, {
        title: props.title,
        message: props.message,
        closable: props.closable,
        onResolve: handleResolve,
        onReject: handleReject,
        buttons: props.buttons,
      }),
      wrapper,
    );
  });
  return promise;
}

export function alert(message: string) {
  showAlert({ message: message });
}

export function showAlert(props: { title?: string; message?: string }) {
  showModal({
    title: props.title,
    message: props.message,
    closable: true,
    buttons: [
      {
        label: 'OK',
        variant: 'primary',
      },
    ],
  });
}

export function confirm(message: string) {
  return showConfirm({ message: message });
}

export function showConfirm(props: { title?: string; message?: string }): Promise<void> {
  return showModal({
    title: props.title,
    message: props.message,
    closable: false,
    buttons: [
      {
        label: 'OK',
        variant: 'primary',
        doResolve: true,
      },
      {
        label: 'Annulla',
        variant: 'light',
        doReject: true,
      },
    ],
  });
}

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export function getContentStateVariant(state: Interfaces.ContentState) {
  return state.toString() === Interfaces.ContentState[Interfaces.ContentState.READY]
    ? 'success'
    : state.toString() === Interfaces.ContentState[Interfaces.ContentState.WORKING]
    ? 'warning'
    : state.toString() === Interfaces.ContentState[Interfaces.ContentState.OFFLINE]
    ? 'danger'
    : 'secondary';
}

export function getAliEntityStateVariant(state: Interfaces.AliEntityStateEnum) {
  return state.toString() === Interfaces.AliEntityStateEnum[Interfaces.AliEntityStateEnum.ONLINE]
    ? 'success'
    : state.toString() === Interfaces.AliEntityStateEnum[Interfaces.AliEntityStateEnum.DRAFT]
    ? 'warning'
    : state.toString() === Interfaces.AliEntityStateEnum[Interfaces.AliEntityStateEnum.OFFLINE]
    ? 'danger'
    : 'secondary';
}

export function getAnnouncementStateVariant(state: Interfaces.AnnouncementStateEnum) {
  return state.toString() === Interfaces.AnnouncementStateEnum[Interfaces.AnnouncementStateEnum.APPROVED]
    ? 'success'
    : state.toString() === Interfaces.AnnouncementStateEnum[Interfaces.AnnouncementStateEnum.DRAFT]
    ? 'warning'
    : state.toString() === Interfaces.AnnouncementStateEnum[Interfaces.AnnouncementStateEnum.REJECTED]
    ? 'danger'
    : state.toString() === Interfaces.AnnouncementStateEnum[Interfaces.AnnouncementStateEnum.EXPIRED]
    ? 'dark'
    : 'secondary';
}

export function formatCurrency(value: number, currency: string, locale: string): string {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });
  return formatter.format(value);
}

export const intlHtmlTagsFormatter = {
  i: (chunk: string) => <i>{chunk}</i>,
  b: (chunk: string) => <b>{chunk}</b>,
};

export function checkIndexingConsistency(value: any, intl: IntlShape) {
  if (value === undefined || value === null) {
    inconsistentIndexingAlert(intl);
  }
}

export function inconsistentIndexingAlert(intl: IntlShape) {
  window.alert(t('error.inconsistentIndexing', intl));
}
