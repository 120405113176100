import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArrayRenderProps } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import CreateUpdateCardListControlsAdd, {
  Props as CreateUpdateCardListControlsAddProps,
} from './CreateUpdateCardListControlsAdd';
import { bs } from './export';
import { wait } from './Functions';

export interface Props {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  length: number;
  showHr?: boolean;
}

const CreateUpdateCardListControls: React.FunctionComponent<Props> & {
  Add: typeof CreateUpdateCardListControlsAdd;
} = (props) => {
  return (
    <>
      <bs.ButtonToolbar>
        <bs.Button
          variant="light"
          className="mr-2"
          type="button"
          onClick={() => {
            props.arrayHelpers.swap(props.index, props.index + 1);
            fixFormikArrayHelpersSwapBug(props.arrayHelpers);
          }}
          disabled={props.index === props.length - 1}
        >
          <FontAwesomeIcon icon="chevron-down" /> <FormattedMessage tagName="span" id="editForm.list.moveDown" />
        </bs.Button>
        <bs.Button
          variant="light"
          className="mr-2"
          type="button"
          onClick={() => {
            props.arrayHelpers.swap(props.index, props.index - 1);
            fixFormikArrayHelpersSwapBug(props.arrayHelpers);
          }}
          disabled={props.index === 0}
        >
          <FontAwesomeIcon icon="chevron-up" /> <FormattedMessage tagName="span" id="editForm.list.moveUp" />
        </bs.Button>
        <bs.Button
          className="mr-2"
          variant="danger"
          type="button"
          onClick={() => props.arrayHelpers.remove(props.index)}
        >
          <FontAwesomeIcon icon="minus" /> <FormattedMessage tagName="span" id="editForm.list.remove" />
        </bs.Button>
      </bs.ButtonToolbar>
      {props.showHr && <hr />}
    </>
  );
};

function fixFormikArrayHelpersSwapBug(arrayHelpers: FieldArrayRenderProps) {
  wait(10).then(() => {
    arrayHelpers.form.validateForm();
  });
}

CreateUpdateCardListControls.defaultProps = {
  showHr: true,
};

CreateUpdateCardListControls.Add = CreateUpdateCardListControlsAdd;

export default CreateUpdateCardListControls;
