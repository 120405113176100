import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Interfaces } from './export';
import { bs } from './export';

interface Props {
  variant?: string;
  heading?: React.ReactElement;
  title?: Interfaces.FormattedMessageProps;
  table?: React.ReactElement;
  showFooter?: boolean;
  footer?: React.ReactElement;
  headerSize?: 'h5' | 'h6';
}

const Card: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  return (
    <bs.Card data-b2x-sticky-container className="mb-3">
      {(props.heading || props.title) && (
        <bs.Card.Header data-b2x-sticky-element as={props.headerSize}>
          {props.heading ?? <FormattedMessage {...props.title!} />}
        </bs.Card.Header>
      )}
      <bs.Card.Body>{props.children}</bs.Card.Body>
      {props.table}
      {props.showFooter && props.footer && <bs.Card.Footer>{props.footer}</bs.Card.Footer>}
    </bs.Card>
  );
};

Card.defaultProps = {
  variant: 'default',
  showFooter: true,
  headerSize: 'h5',
};

export default Card;
