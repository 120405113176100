import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AnnouncementPage from './AnnouncementPage';
import AnnouncementsPage from './AnnouncementsPage';

interface Props {}

const AnnouncementsRouter: React.FunctionComponent<Props> = (props) => {

  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact component={AnnouncementsPage} />
      <Route path={`${match.path}/:announcementId`} exact component={AnnouncementPage} />
    </Switch>
  );
};

export default AnnouncementsRouter;
