import { IDataGridOptions } from 'devextreme-react/data-grid';
import * as React from 'react';
import { de, Interfaces, SearchContext } from './export';
import { PickerContext } from './Picker';

export interface DataGridRowKey {
  id: string;
}

export type DataGridRow = object & DataGridRowKey;

interface Props extends IDataGridOptions {}

const DataGrid: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const searchContext = React.useContext(SearchContext);
  const pickerContext = React.useContext(PickerContext);
  return React.useMemo(() => {
    return (
      <de.DataGrid
        onSelectionChanged={searchContext.handleDataGridSelectionChange}
        onOptionChanged={onOptionChanged}
        defaultSelectedRowKeys={pickerContext.pickedIds}
        {...props}
      >
        <de.DataGrid.Paging defaultPageSize={getDefaultPageSize()} />
        {props.children}
      </de.DataGrid>
    );
  }, [searchContext.dtos]);
};

function getDefaultPageSize(): number | undefined {
  if (localStorage.getItem(Interfaces.localStorageKey.PAGE_SIZE)) {
    return +localStorage.getItem(Interfaces.localStorageKey.PAGE_SIZE)!;
  } else {
    return undefined;
  }
}

function onOptionChanged(e: { fullName?: string | undefined; value?: any }) {
  if (e.fullName === 'paging.pageSize') {
    localStorage.setItem(Interfaces.localStorageKey.PAGE_SIZE, e.value);
  }
}

export default DataGrid;
