import axios, { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { Functions, Interfaces, SearchContext, SearchContextProps, StoreContext } from './export';
import FiltersFormCard from './FiltersFormCard';
import FormGroup from './FormGroup';
import * as rest from './Rest';

interface Props {}

interface FormValues {
  idSelectedStoreGeoArea: number;
  isASeller: boolean;
  isAStore: boolean;
  limit: number;
  name: string;
}

const validationSchema = Yup.object().shape({});

const ImagesFiltersForm: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(StoreContext);
  const searchContext = React.useContext<SearchContextProps<Interfaces.ImageDto>>(SearchContext);

  React.useEffect(() => {
    handleSubmit(initialValues);
  }, []);

  const initialValues: FormValues = {
    idSelectedStoreGeoArea: storeContext.idStoreGeoArea,
    isASeller: false,
    isAStore: true,
    limit: -1,
    name: '',
  };

  return (
    <Formik<FormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <FiltersFormCard>
            <FormGroup name="name" label={{ formattedMessage: { id: 'imagesPage.filtersForm.name.label' } }} />
          </FiltersFormCard>
        </Form>
      )}
    </Formik>
  );

  function handleSubmit(values: FormValues, formikHelpers?: FormikHelpers<FormValues>) {
    axios({
      method: 'get',
      params: values,
      paramsSerializer: Functions.filterEmptyParamsSerializer,
      url: rest.imageSearch(),
    }).then((response: AxiosResponse) => {
      const images = response.data as Interfaces.ImageDto[];
      searchContext.handleSearch(images);
    });
  }
};

export default ImagesFiltersForm;
