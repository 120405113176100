import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { Route } from 'react-router-dom';
import AnnouncementsRouter from './AnnouncementsRouter';
import ConventionsRouter from './ConventionsRouter';
import Header from './Header';
import HomePage from './HomePage';
import HomePageManagementPage from './HomePageManagementPage';
import LoginPage from './LoginPage';
import Sidebar from './Sidebar';

interface Props {}

const App: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(b2x.StoreContext);

  return (
    <>
      <Header />
      <Sidebar />
      <main className="p-3">
        {!storeContext.userContext.loginDto ? (
          <LoginPage />
        ) : (
          <>
            <Route exact path="/" component={HomePage} />
            <Route path="/conventions" component={ConventionsRouter} />
            <Route path="/announcements" component={AnnouncementsRouter} />
            <Route path="/homepage-management" component={HomePageManagementPage} />
            <Route path="/images" component={b2x.ImagesRouter} />
            <Route path="/files" component={b2x.FilesRouter} />
          </>
        )}
      </main>
    </>
  );
};

export default App;
