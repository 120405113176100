import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import qs from 'qs';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { SearchContextProps, StoreContext } from 'react-b2x/src/export';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import ConventionsFiltersForm from './ConventionsFiltersForm';
import ConventionsGrid from './ConventionsGrid';
import ConventionsUploader from './ConventionsUploader';

interface Props {}

const ConventionsPage: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(StoreContext);
  const intl = useIntl();
  const match = useRouteMatch();

  return (
    <b2x.SearchPage
      title={<FormattedMessage id="conventionsPage.title" />}
      filters={<ConventionsFiltersForm />}
      selectionActions={[
        {
          action: deleteSelected,
          labelId: 'conventionsPage.results.selectionActions.delete.label',
        },
        {
          action: duplicateSelected,
          labelId: 'conventionsPage.results.selectionActions.duplicate.label',
          max: 1,
        },
        {
          labelId: 'conventionsPage.results.selectionActions.changeState.label',
          children: [
            {
              action: changeStateDraftSelected,
              labelId: 'conventionsPage.results.selectionActions.changeState.DRAFT.label',
            },
            {
              action: changeStateOfflineSelected,
              labelId: 'conventionsPage.results.selectionActions.changeState.OFFLINE.label',
            },
            {
              action: changeStateOnlineSelected,
              labelId: 'conventionsPage.results.selectionActions.changeState.ONLINE.label',
            },
          ],
        },
      ]}
      actions={(searchContext: SearchContextProps<b2x.Interfaces.AliConventionDto>) => [
        <span className="ml-2" key="1">
          <ConventionsUploader />
        </span>,
        <LinkContainer key="2" to={`${match.url}/new`}>
          <b2x.bs.Button variant="primary" className="ml-2">
            <FontAwesomeIcon icon="plus" /> <FormattedMessage tagName="span" id="conventionsPage.new" />
          </b2x.bs.Button>
        </LinkContainer>,
      ]}
      table={<ConventionsGrid />}
    />
  );

  function deleteSelected(searchContext: SearchContextProps<b2x.Interfaces.AliConventionDto>) {
    b2x.Functions.showConfirm({
      message: b2x.Functions.translate(
        'searchPage.selectionActions.delete',
        { selected: searchContext.selectedIds.length },
        intl,
      ),
    })
      .then(() => {
        axios({
          method: 'delete',
          data: qs.stringify({
            contentIds: JSON.stringify(searchContext.selectedIds),
            idSelectedStoreGeoArea: storeContext.idStoreGeoArea,
          }),
          url: b2x.Rest.contentDelete(),
        }).then(() => {
          searchContext.refresh();
        });
      })
      .catch(() => {});
  }

  function duplicateSelected(searchContext: SearchContextProps<b2x.Interfaces.AliConventionDto>) {
    window.console.log('duplicate selectedIds: ' + searchContext.selectedIds);
    window.console.log('duplicate selected: ' + JSON.stringify(searchContext.selectedRows));
  }

  function changeStateDraftSelected(searchContext: SearchContextProps<b2x.Interfaces.AliConventionDto>) {
    changeStateSelected(searchContext, b2x.Interfaces.AliEntityStateEnum.DRAFT);
  }

  function changeStateOfflineSelected(searchContext: SearchContextProps<b2x.Interfaces.AliConventionDto>) {
    changeStateSelected(searchContext, b2x.Interfaces.AliEntityStateEnum.OFFLINE);
  }

  function changeStateOnlineSelected(searchContext: SearchContextProps<b2x.Interfaces.AliConventionDto>) {
    changeStateSelected(searchContext, b2x.Interfaces.AliEntityStateEnum.ONLINE);
  }

  function changeStateSelected(
    searchContext: SearchContextProps<b2x.Interfaces.AliConventionDto>,
    state: b2x.Interfaces.AliEntityStateEnum,
  ) {
    b2x.Functions.showConfirm({
      message: b2x.Functions.translate(
        'conventionsPage.results.selectionActions.changeState.confirm',
        {
          selected: searchContext.selectedIds.length,
          state: b2x.Functions.t(
            `conventionsPage.results.selectionActions.changeState.${b2x.Interfaces.AliEntityStateEnum[state]}.label`,
            intl,
          ),
        },
        intl,
      ),
    })
      .then(() => {
        axios({
          method: 'post',
          data: qs.stringify({
            contentIds: JSON.stringify(searchContext.selectedIds),
            idState:
              state === b2x.Interfaces.AliEntityStateEnum.DRAFT
                ? 4701
                : state === b2x.Interfaces.AliEntityStateEnum.OFFLINE
                ? 4703
                : state === b2x.Interfaces.AliEntityStateEnum.ONLINE
                ? 4702
                : undefined,
            idSelectedStoreGeoArea: storeContext.idStoreGeoArea,
          }),
          url: b2x.Rest.contentSetState(),
        }).then(() => {
          searchContext.refresh();
        });
      })
      .catch(() => {});
  }
};

export default ConventionsPage;
