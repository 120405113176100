import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Interfaces } from './export';
import ImagesFiltersForm from './ImagesFiltersForm';
import ImagesGrid from './ImagesGrid';
import ImageUploader from './ImageUploader';
import SearchPage from './SearchPage';

interface Props {}

const ImagesPage: React.FunctionComponent<Props> = (props) => {
  return (
    <SearchPage<Interfaces.ImageDto>
      title={<FormattedMessage id="imagesPage.title" />}
      filters={<ImagesFiltersForm />}
      selectionActions={[
        {
          action: () => undefined,
          labelId: 'imagesPage.results.action1.label',
        },
        {
          action: () => undefined,
          labelId: 'imagesPage.results.action2.label',
        },
      ]}
      actions={(searchContext) => [
        <ImageUploader key="1" name="" showFileName={false} showThumbnail={false} />,
      ]}
      table={<ImagesGrid />}
    />
  );
};

export default ImagesPage;
