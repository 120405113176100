import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

interface Props {}

enum Col {
  CODE = 'code',
  TYPE = 'type',
  TITLE = 'title',
  SHORT_DESCRIPTION = 'shortDescription',
  DETAILS = 'details',
  TERMS = 'terms',
  PRICE = 'price',
  CITY = 'city',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  PHONE_SECONDARY = 'phoneSecondary',
  CUSTOMER = 'customer',
  CATEGORY = 'category',
  PUBLICATION_DATE = 'publicationDate',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  STATE = 'state',
  CREATION_DATE = 'creationDate',
  LAST_UPDATING_DATE = 'lastUpdatingDate',
}

export interface AnnouncementRow extends b2x.de.DataGridRow<b2x.Interfaces.AnnouncementDto> {
  code: string;
  type: string;
  title: string;
  shortDescription: string;
  details: string;
  terms: string;
  price: string;
  city: string;
  name: string;
  email: string;
  phone: string;
  phoneSecondary: string;
  customer: string;
  category: string;
  publicationDate: Date;
  startDate: Date;
  endDate: Date;
  state: string;
  creationDate: Date;
  lastUpdatingDate: Date;
}

const AnnouncementsGrid: React.FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const searchContext = React.useContext(b2x.SearchContext);

  const dataSource: AnnouncementRow[] = [];

  const announcements = searchContext.dtos as b2x.Interfaces.AnnouncementDto[];
  for (const announcement of announcements) {
    dataSource.push({
      dto: announcement,
      id: announcement.id.toString(),
      code: announcement.code,
      type: announcement.type
        ? b2x.Functions.t('announcementPage.editForm.type.option.' + announcement.type, intl)
        : '',
      title: announcement.title ?? '',
      shortDescription: announcement.shortDescription ?? '',
      details: announcement.details ?? '',
      terms: announcement.terms ?? '',
      price: announcement.price?.toString() ?? '',
      city: announcement.city ?? '',
      name: announcement.name ?? '',
      email: announcement.email ?? '',
      phone: announcement.phone ?? '',
      phoneSecondary: announcement.phoneSecondary ?? '',
      customer: announcement.customer.username,
      publicationDate: announcement.datePublication ?? '',
      startDate: announcement.dateStart ?? '',
      endDate: announcement.dateEnd ?? '',
      state: announcement.state
        ? b2x.Functions.t('announcementPage.editForm.state.option.' + announcement.state, intl)
        : '',
      category: announcement.categories ? announcement.categories[0].description : '',
      creationDate: announcement.creationDate,
      lastUpdatingDate: announcement.lastUpdatingDate,
    });
  }

  return (
    <b2x.DataGrid dataSource={dataSource}>
      <b2x.de.DataGrid.Column
        dataField={Col.CODE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.code', intl)}
        cellRender={({ key, value }) => <Link to={`${location.pathname}/${key}`}>{value}</Link>}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.STATE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.state', intl)}
        cellRender={({ data, value }: { data: AnnouncementRow; value: string }) => (
          <span className={`text-${b2x.Functions.getAnnouncementStateVariant(data.dto.state)}`}>{value}</span>
        )}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.TYPE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.type', intl)}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.TITLE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.title', intl)}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.SHORT_DESCRIPTION}
        caption={b2x.Functions.t('announcementsPage.results.announcement.shortDescription', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.DETAILS}
        caption={b2x.Functions.t('announcementsPage.results.announcement.details', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.TERMS}
        caption={b2x.Functions.t('announcementsPage.results.announcement.terms', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column.Price
        dataField={Col.PRICE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.price', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CITY}
        caption={b2x.Functions.t('announcementsPage.results.announcement.city', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.NAME}
        caption={b2x.Functions.t('announcementsPage.results.announcement.name', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.EMAIL}
        caption={b2x.Functions.t('announcementsPage.results.announcement.email', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.PHONE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.phone', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.PHONE_SECONDARY}
        caption={b2x.Functions.t('announcementsPage.results.announcement.phoneSecondary', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CUSTOMER}
        caption={b2x.Functions.t('announcementsPage.results.announcement.customer', intl)}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CATEGORY}
        caption={b2x.Functions.t('announcementsPage.results.announcement.category', intl)}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.PUBLICATION_DATE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.publicationDate', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.START_DATE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.startDate', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.END_DATE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.endDate', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.CREATION_DATE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.creationDate', intl)}
        sortOrder="desc"
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.LAST_UPDATING_DATE}
        caption={b2x.Functions.t('announcementsPage.results.announcement.lastUpdatingDate', intl)}
      />
    </b2x.DataGrid>
  );
};

export default AnnouncementsGrid;
