import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { useIntl } from 'react-intl';
import * as rest from './Rest';

interface Props {}

const LoginPage: React.FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const image: b2x.ImageProps = {
    alt: b2x.Functions.t('title', intl),
    height: 67,
    src: require('./images/logo.png'),
    src2x: require('./images/logo@2x.png'),
    title: b2x.Functions.t('title', intl),
  };

  return <b2x.LoginPage image={image} restUrl={rest.userLogin()} />;
};

export default LoginPage;
