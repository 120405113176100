import * as React from 'react';
import FilesPage from './FilesPage';
import Picker, { PickerContextProps } from './Picker';

interface Props {
  name: string;
  defaultSelection?: string;
  disabled?: boolean;
  onPick: (value?: string) => void;
}

const FilePicker: React.FunctionComponent<Props> = (props) => {
  return (
    <Picker
      defaultSelection={props.defaultSelection ? [props.defaultSelection] : []}
      onPick={props.onPick}
      name={props.name}
      component={<FilesPage />}
      render={(pickerContext: PickerContextProps) => <>{pickerContext.pickedIds.map((id) => id)}</>}
      disabled={props.disabled}
    />
  );
};

export default FilePicker;
