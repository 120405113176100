import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { FormattedMessage } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';

interface Props {}

const HomePage: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <b2x.PageHeader>
        <FormattedMessage id="homePage.title" />
      </b2x.PageHeader>
      <div className="mb-n3">
        <b2x.bs.Row className="big-gutters">
          <b2x.bs.Col sm={6}>
            <Block id="conventions" icon="star" to="/conventions" />
          </b2x.bs.Col>
          <b2x.bs.Col sm={6}>
            <Block id="products" icon="shopping-cart" to="/products" />
          </b2x.bs.Col>
        </b2x.bs.Row>
        <b2x.bs.Row className="big-gutters">
          <b2x.bs.Col sm={6}>
            <Block id="buyingGroups" icon="list-ul" to="/buying-groups" />
          </b2x.bs.Col>
          <b2x.bs.Col sm={6}>
            <Block id="announcements" icon="bullhorn" to="/announcements" />
          </b2x.bs.Col>
        </b2x.bs.Row>
        <b2x.bs.Row className="big-gutters">
          <b2x.bs.Col sm={6}>
            <Block id="surveys" icon="chart-pie" to="/surveys" />
          </b2x.bs.Col>
          <b2x.bs.Col sm={6}>
            <Block id="homepageManagement" icon="wrench" to="/homepage-management" />
          </b2x.bs.Col>
        </b2x.bs.Row>
        <b2x.bs.Row className="big-gutters">
          <b2x.bs.Col sm={6}>
            <Block id="images" icon="images" to="/images" />
          </b2x.bs.Col>
          <b2x.bs.Col sm={6}>
            <Block id="files" icon="file" to="/files" />
          </b2x.bs.Col>
        </b2x.bs.Row>
      </div>
    </>
  );
};

function Block(props: { id: string; icon: IconProp; to: string }) {
  const titleId = 'homePage.' + props.id + '.title';
  const bodyId = 'homePage.' + props.id + '.body';
  const actionId = 'homePage.' + props.id + '.action';
  return (
    <div className="mb-3">
      <b2x.Card
        heading={
          <>
            <FontAwesomeIcon icon={props.icon} /> <FormattedMessage tagName="span" id={titleId} />
          </>
        }
        headerSize="h6"
      >
        <FormattedMessage tagName="p" id={bodyId} />
        <LinkContainer to={props.to}>
          <b2x.bs.Button variant="primary">
            <FormattedMessage id={actionId} />
          </b2x.bs.Button>
        </LinkContainer>
      </b2x.Card>
    </div>
  );
}

export default HomePage;
