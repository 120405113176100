const restPath = '/rest';

const imagePath = 'image';
export const imageSearch = () => restPath + '/' + imagePath + '/search';
export const imageGet = (id: string) => restPath + '/images/' + id + '/';
export const imageUpload = () => restPath + '/fileupload/image/';

const filePath = 'file';
export const fileSearch = () => restPath + '/' + filePath + '/search';
export const fileGet = (id: string) => restPath + '/files/' + id + '/';
export const fileUpload = () => restPath + '/fileupload/file/';

const contentPath = 'content';
export const contentGet = () => restPath + '/' + contentPath + '/getById';
export const contentUpdate = () => restPath + '/' + contentPath + '/update';
export const contentSetState = () => restPath + '/' + contentPath + '/setState';
export const contentDelete = () => restPath + '/' + contentPath + '/removeList';
export const indexingCheck = () => restPath + '/' + contentPath + '/indexing/check';

const cachePath = 'cache';
export const cacheGet = () => restPath + '/' + cachePath + '/get';
