import { FormikErrors } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Card from './Card';
import CreateUpdateCardFieldset from './CreateUpdateCardFieldset';
import CreateUpdateCardListControls from './CreateUpdateCardListControls';
import { bs, Functions } from './export';

interface Props<Dto, FormValues> {
  dto?: Dto;
  formErrors: FormikErrors<FormValues>;
}

interface Context {
  editEnabled: boolean;
}

export const CreateUpdateCardContext = React.createContext<Context>({
  editEnabled: false,
});

// type CreateUpdateCardType<P> = React.FunctionComponent<P> & {
//   Fieldset: typeof CreateUpdateCardFieldset;
//   ListControls: typeof CreateUpdateCardListControls;
// };

const CreateUpdateCard = <Dto extends object, FormValues extends object>(props: React.PropsWithChildren<Props<Dto, FormValues>>) => {
  const [editEnabled, setEditEnabled] = React.useState<boolean>(props.dto === undefined);

  return (
    <Card
      heading={
        <bs.ButtonToolbar>
          {!editEnabled && (
            <bs.Button className="ml-2" variant="primary" type="button" onClick={handleEditButtonClick}>
              <FormattedMessage id="edit" />
            </bs.Button>
          )}
          {props.dto && editEnabled && (
            <bs.Button className="ml-2" variant="light" type="button" onClick={handleCancelButtonClick}>
              <FormattedMessage id="cancel" />
            </bs.Button>
          )}
          {editEnabled && (
            <bs.Button
              className="ml-2"
              variant="primary"
              type="submit"
              disabled={!Functions.isFormValid(props.formErrors)}
            >
              <FormattedMessage id="submit" />
            </bs.Button>
          )}
        </bs.ButtonToolbar>
      }
      showFooter={!Functions.isFormValid(props.formErrors)}
      footer={
        <bs.Alert variant="danger" className="my-0">
          {Functions.printErrors(props.formErrors)}
        </bs.Alert>
      }
    >
      <CreateUpdateCardContext.Provider value={{ editEnabled: editEnabled }}>
        <div className="mb-n3">{props.children}</div>
      </CreateUpdateCardContext.Provider>
    </Card>
  );

  function handleEditButtonClick() {
    toggleEditEnablement(true);
  }

  function handleCancelButtonClick() {
    toggleEditEnablement(false);
  }

  function toggleEditEnablement(editEnabledProp?: boolean) {
    if (!editEnabledProp) {
      editEnabledProp = !editEnabled;
    }
    setEditEnabled(editEnabledProp);
  }
};

CreateUpdateCard.Fieldset = CreateUpdateCardFieldset;
CreateUpdateCard.ListControls = CreateUpdateCardListControls;

export default CreateUpdateCard;
