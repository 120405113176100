import * as React from 'react';
import { useIntl } from 'react-intl';
import { DataGrid, de, Functions, Interfaces, SearchContext, SearchContextProps } from './export';

interface Props {}

enum Col {
  FILE = 'file',
  NAME = 'name',
  MIME_TYPE = 'mimeType',
  CREATION_DATE = 'creationDate',
}

interface Row extends de.DataGridRow<Interfaces.FileDto> {
  file: string;
  name: string;
  mimeType: string;
  creationDate: Date;
}

const FilesGrid: React.FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const searchContext = React.useContext<SearchContextProps<Interfaces.FileDto>>(SearchContext);

  const dataSource: Row[] = [];

  for (const file of searchContext.dtos) {
    dataSource.push({
      dto: file,
      creationDate: file.creationDate,
      file: file.idFile,
      id: file.idFile,
      mimeType: file.mimeType,
      name: file.name,
    });
  }

  return (
    <DataGrid dataSource={dataSource} selection={{ mode: 'single' }}>
      <de.DataGrid.Column dataField={Col.FILE} caption={Functions.t('filesPage.results.file.file', intl)} />
      <de.DataGrid.Column dataField={Col.NAME} caption={Functions.t('filesPage.results.file.name', intl)} />
      <de.DataGrid.Column dataField={Col.MIME_TYPE} caption={Functions.t('filesPage.results.file.mimeType', intl)} />
      <de.DataGrid.Column.Date
        dataField={Col.CREATION_DATE}
        caption={Functions.t('filesPage.results.file.creationDate', intl)}
        sortOrder="desc"
      />
    </DataGrid>
  );
};

export default FilesGrid;
