const restPath = '/api';

const userPath = 'user';
export const userLogin = () => restPath + '/' + userPath + '/login';

const conventionPath = 'convention';
export const conventionSearch = () => restPath + '/' + conventionPath + '/search';
export const conventionConfig = () => restPath + '/' + conventionPath + '/config';
export const conventionGet = (id: string) => restPath + '/' + conventionPath + '/' + id + '/';
export const conventionCreateUpdate = () => restPath + '/' + conventionPath + '/createUpdate';
export const conventionUpload = () => restPath + '/' + conventionPath + '/upload';

const announcementPath = 'announcement';
export const announcementSearch = () => restPath + '/' + announcementPath + '/search';
export const announcementConfig = () => restPath + '/' + announcementPath + '/config';
export const announcementApprove = () => restPath + '/' + announcementPath + '/approve';
export const announcementReject = () => restPath + '/' + announcementPath + '/reject';
export const announcementGet = (id: string) => restPath + '/' + announcementPath + '/' + id + '/';

const configurationPath = 'configuration';
export const configurationHome = () => restPath + '/' + configurationPath + '/home';
