import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

interface Props {}

enum Col {
  CODE = 'code',
  TITLE = 'title',
  TYPOLOGY = 'typology',
  CATEGORY = 'category',
  STATE = 'state',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CREATION_DATE = 'creationDate',
  LAST_UPDATING_DATE = 'lastUpdatingDate',
  VISUAL_CERVED = 'visuraCerved',
  BUSINESS_NAME = 'businessName',
  FISCAL_CODE = 'fiscalCode',
  EMAIL = 'email',
  COMMERCIAL_REFERENT = 'commercialReferent',
  ALI_REFERENT = 'aliReferent',
  REFERENT_CONTACT = 'referentContact',
  FIRST_AGREEMENT_DATE = 'firstAgreementDate',
  CONTRACT_TYPE = 'contractType',
  CONTROL_231 = 'control231',
  CONTROL_GRPD = 'controlGdpr',
  CT_OF_REFERENCE = 'ctOfReference',
  IN_REVIEW = 'inReview',
  ADDRESS1 = 'address1',
  ADDRESS2 = 'address2',
  ADDRESS3 = 'address3',
  CIVIC_CODE = 'civicCode',
  ZONE_NAME = 'zoneName',
  PROV_STATE = 'provState',
  CITY_NAME = 'cityName',
  ZIP_CODE = 'zipCode',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
}

interface Row extends b2x.de.DataGridRow<b2x.Interfaces.AliConventionDto> {
  code: string;
  title: string;
  typology: string;
  category: string;
  state: string;
  startDate?: Date;
  endDate?: Date;
  creationDate: Date;
  lastUpdatingDate: Date;
  visuraCerved: string;
  businessName?: string;
  fiscalCode?: string;
  email?: string;
  commercialReferent?: string;
  aliReferent?: string;
  referentContact?: string;
  firstAgreementDate?: Date;
  contractType?: string;
  control231: string;
  controlGdpr: string;
  ctOfReference: string;
  inReview: string;
  address1?: string;
  address2?: string;
  address3?: string;
  civicCode?: string;
  zoneName?: string;
  provState?: string;
  cityName?: string;
  zipCode?: string;
  latitude?: string;
  longitude?: string;
}

const ConventionsGrid: React.FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const searchContext = React.useContext(b2x.SearchContext);

  const dataSource: Row[] = [];

  const conventions = searchContext.dtos as b2x.Interfaces.AliConventionDto[];
  for (const convention of conventions) {
    b2x.Functions.checkIndexingConsistency(convention.category, intl);

    dataSource.push({
      dto: convention,
      aliReferent: convention.aliReferent,
      businessName: convention.businessName,
      category: convention.category.name,
      code: convention.code,
      commercialReferent: convention.commercialReferent,
      contractType: convention.contractType
        ? b2x.Functions.t('conventionPage.editForm.contractType.option.' + convention.contractType, intl)
        : '',
      control231: convention.control231
        ? b2x.Functions.t('conventionPage.editForm.control231.option.' + convention.control231, intl)
        : '',
      controlGdpr: convention.controlGdpr
        ? b2x.Functions.t('conventionPage.editForm.controlGdpr.option.' + convention.controlGdpr, intl)
        : '',
      creationDate: convention.creationDate,
      ctOfReference: convention.ctOfReference
        ? b2x.Functions.t('conventionPage.editForm.ctOfReference.option.' + convention.ctOfReference, intl)
        : '',
      email: convention.email,
      endDate: convention.endDate,
      firstAgreementDate: convention.firstAgreementDate,
      fiscalCode: convention.fiscalCode,
      id: convention.id.toString(),
      inReview: convention.inReview
        ? b2x.Functions.t('conventionPage.editForm.inReview.option.' + convention.inReview, intl)
        : '',
      lastUpdatingDate: convention.lastUpdatingDate,
      referentContact: convention.referentContact,
      startDate: convention.startDate,
      state: convention.state ? b2x.Functions.t('conventionPage.editForm.state.option.' + convention.state, intl) : '',
      title: convention.title,
      typology: convention.typology
        ? b2x.Functions.t('conventionPage.editForm.typology.option.' + convention.typology, intl)
        : '',
      visuraCerved: convention.visuraCerved
        ? b2x.Functions.t('conventionPage.editForm.visuraCerved.option.' + convention.visuraCerved, intl)
        : '',
      address1: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.ADDRESS1),
      address2: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.ADDRESS2),
      address3: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.ADDRESS3),
      civicCode:
        convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.CIVIC_CODE),
      zoneName: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.ZONE_NAME),
      provState:
        convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.PROV_STATE),
      cityName: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.CITY_NAME),
      zipCode: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.ZIP_CODE),
      latitude: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.LATITUDE),
      longitude: convention.addresses && b2x.Functions.joinArrayItemsSpecificField(convention.addresses, Col.LONGITUDE),
    });
  }

  return (
    <b2x.DataGrid dataSource={dataSource}>
      <b2x.de.DataGrid.Column
        dataField={Col.CODE}
        caption={b2x.Functions.t('conventionsPage.results.convention.code', intl)}
        cellRender={({ key, value }) => <Link to={`${location.pathname}/${key}`}>{value}</Link>}
        sortOrder="desc"
        sortIndex={2}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.TITLE}
        caption={b2x.Functions.t('conventionsPage.results.convention.title', intl)}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.TYPOLOGY}
        caption={b2x.Functions.t('conventionsPage.results.convention.typology', intl)}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CATEGORY}
        caption={b2x.Functions.t('conventionsPage.results.convention.category', intl)}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.STATE}
        caption={b2x.Functions.t('conventionsPage.results.convention.state', intl)}
        cellRender={({ data, value }: { data: Row; value: string }) => (
          <span className={`text-${b2x.Functions.getAliEntityStateVariant(data.dto.state)}`}>{value}</span>
        )}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.START_DATE}
        caption={b2x.Functions.t('conventionsPage.results.convention.startDate', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.END_DATE}
        caption={b2x.Functions.t('conventionsPage.results.convention.endDate', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.ADDRESS1}
        caption={b2x.Functions.t('address.address1.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.ADDRESS2}
        caption={b2x.Functions.t('address.address2.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.ADDRESS3}
        caption={b2x.Functions.t('address.address3.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CIVIC_CODE}
        caption={b2x.Functions.t('address.civicCode.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.ZONE_NAME}
        caption={b2x.Functions.t('address.zoneName.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.PROV_STATE}
        caption={b2x.Functions.t('address.provState.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CITY_NAME}
        caption={b2x.Functions.t('address.cityName.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.ZIP_CODE}
        caption={b2x.Functions.t('address.zipCode.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.LATITUDE}
        caption={b2x.Functions.t('address.latitude.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.LONGITUDE}
        caption={b2x.Functions.t('address.longitude.label', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.CREATION_DATE}
        caption={b2x.Functions.t('conventionsPage.results.convention.creationDate', intl)}
        sortOrder="desc"
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.LAST_UPDATING_DATE}
        caption={b2x.Functions.t('conventionsPage.results.convention.lastUpdatingDate', intl)}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.VISUAL_CERVED}
        caption={b2x.Functions.t('conventionsPage.results.convention.visuraCerved', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.BUSINESS_NAME}
        caption={b2x.Functions.t('conventionsPage.results.convention.businessName', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.FISCAL_CODE}
        caption={b2x.Functions.t('conventionsPage.results.convention.fiscalCode', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.EMAIL}
        caption={b2x.Functions.t('conventionsPage.results.convention.email', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.COMMERCIAL_REFERENT}
        caption={b2x.Functions.t('conventionsPage.results.convention.commercialReferent', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.ALI_REFERENT}
        caption={b2x.Functions.t('conventionsPage.results.convention.aliReferent', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.REFERENT_CONTACT}
        caption={b2x.Functions.t('conventionsPage.results.convention.referentContact', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column.Date
        dataField={Col.FIRST_AGREEMENT_DATE}
        caption={b2x.Functions.t('conventionsPage.results.convention.firstAgreementDate', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CONTRACT_TYPE}
        caption={b2x.Functions.t('conventionsPage.results.convention.contractType', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CONTROL_231}
        caption={b2x.Functions.t('conventionsPage.results.convention.control231', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CONTROL_GRPD}
        caption={b2x.Functions.t('conventionsPage.results.convention.controlGdpr', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.CT_OF_REFERENCE}
        caption={b2x.Functions.t('conventionsPage.results.convention.ctOfReference', intl)}
        visible={false}
      />
      <b2x.de.DataGrid.Column
        dataField={Col.IN_REVIEW}
        caption={b2x.Functions.t('conventionsPage.results.convention.inReview', intl)}
        visible={false}
      />
    </b2x.DataGrid>
  );
};

export default ConventionsGrid;
