import * as React from 'react';
import { Interfaces } from './export';
import FormCard from './FormCard';

interface Props {
  title?: Interfaces.FormattedMessageProps;
}

const FiltersFormCard: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  return (
    <FormCard
      title={props.title ?? { id: 'filtersForm.title' }}
      submitLabel={{ id: 'filtersForm.submit' }}
      showResetButton={true}
    >
      <div className="mb-n3">{props.children}</div>
    </FormCard>
  );
};

export default FiltersFormCard;
