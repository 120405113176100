import axios, { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import qs from 'qs';
import * as React from 'react';
import * as Yup from 'yup';
import { FormGroup, Interfaces, StoreContext } from './export';
import FormCard from './FormCard';

interface Props {
  restUrl: string;
}

interface FormValues {
  username: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required(),
  username: Yup.string()
    .required()
    .email(),
});

const LoginForm: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(StoreContext);

  return (
    <Formik<FormValues>
      initialValues={{ username: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <FormCard
            title={{ id: 'loginForm.title' }}
            submitLabel={{ id: 'loginForm.submit' }}
          >
            <div className="mb-n3">
              <FormGroup
                name="username"
                label={{ formattedMessage: { id: 'loginForm.username.label' } }}
                noMandatoryStyling={true}
                type="email"
                validationSchema={validationSchema}
              />
              <FormGroup
                name="password"
                label={{ formattedMessage: { id: 'loginForm.password.label' } }}
                noMandatoryStyling={true}
                type="password-current"
                validationSchema={validationSchema}
              />
            </div>
          </FormCard>
        </Form>
      )}
    </Formik>
  );

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    axios({
      data: qs.stringify(values),
      headers: {
        interceptError: true,
      },
      method: 'post',
      url: props.restUrl,
    })
      .then((response: AxiosResponse) => {
        const loginDto = response.data as Interfaces.LoginDto;
        storeContext.userContext.handleLogin(loginDto);
      })
      .catch(() => {
        formikHelpers.setSubmitting(false);
      });
  }
};

export default LoginForm;
