import * as React from 'react';

interface Props {}

const PageHeader: React.FunctionComponent<Props> = (props) => {
  return (
      <h2 className="mb-3 text-center">{props.children}</h2>
  );
};

PageHeader.defaultProps = {};

export default PageHeader;
