import axios from 'axios';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { SearchContextProps } from 'react-b2x/src/export';
import { FormattedMessage, useIntl } from 'react-intl';
import AnnouncementsFiltersForm from './AnnouncementsFiltersForm';
import AnnouncementsGrid from './AnnouncementsGrid';
import * as rest from './Rest';

interface Props {}

const AnnouncementsPage: React.FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <b2x.SearchPage<b2x.Interfaces.AnnouncementDto>
      title={<FormattedMessage id="announcementsPage.title" />}
      filters={<AnnouncementsFiltersForm />}
      selectionActions={[
        {
          action: approveSelected,
          labelId: 'announcementsPage.results.selectionActions.approve.label',
          disabledCheck: checkSelectedToApprove,
        },
        {
          action: rejectSelected,
          labelId: 'announcementsPage.results.selectionActions.reject.label',
          disabledCheck: checkSelectedToReject,
        },
      ]}
      actions={(searchContext: SearchContextProps<b2x.Interfaces.AnnouncementDto>) => []}
      table={<AnnouncementsGrid />}
    />
  );

  function approveSelected(searchContext: SearchContextProps<b2x.Interfaces.AnnouncementDto>) {
    b2x.Functions.showConfirm({
      message: b2x.Functions.translate(
        'announcementsPage.results.selectionActions.approve.confirm',
        { selected: searchContext.selectedIds.length },
        intl,
      ),
    }).then(() => {
      axios({
        method: 'PUT',
        data: searchContext.selectedIds,
        url: rest.announcementApprove(),
      }).then(() => {
        searchContext.refresh();
      });
    });
  }

  function checkSelectedToApprove(searchContext: SearchContextProps<b2x.Interfaces.AnnouncementDto>) {
    let disabledReason;
    searchContext.selectedDtos.forEach((announcement) => {
      if (
        announcement.state.toString() ===
        b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.APPROVED]
      ) {
        disabledReason = b2x.Functions.t(
          'announcementsPage.results.selectionActions.approve.disabledReason.alreadyApproved',
          intl,
        );
      }
    });
    return disabledReason;
  }

  function rejectSelected(searchContext: SearchContextProps<b2x.Interfaces.AnnouncementDto>) {
    b2x.Functions.showConfirm({
      message: b2x.Functions.translate(
        'announcementsPage.results.selectionActions.reject.confirm',
        { selected: searchContext.selectedIds.length },
        intl,
      ),
    }).then(() => {
      axios({
        method: 'PUT',
        data: searchContext.selectedIds,
        url: rest.announcementReject(),
      }).then(() => {
        searchContext.refresh();
      });
    });
  }

  function checkSelectedToReject(searchContext: SearchContextProps<b2x.Interfaces.AnnouncementDto>) {
    let disabledReason;
    searchContext.selectedDtos.forEach((announcement) => {
      if (
        announcement.state.toString() ===
        b2x.Interfaces.AnnouncementStateEnum[b2x.Interfaces.AnnouncementStateEnum.REJECTED]
      ) {
        disabledReason = b2x.Functions.t(
          'announcementsPage.results.selectionActions.reject.disabledReason.alreadyRejected',
          intl,
        );
      }
    });
    return disabledReason;
  }
};

export default AnnouncementsPage;
