import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from './Interfaces';

interface Props {
  message?: string;
  formattedMessage?: FormattedMessageProps;
}

const Message: React.FunctionComponent<Props> = (props) => {
  return props.formattedMessage ? <FormattedMessage {...props.formattedMessage} /> : <>{props.message}</>;
};

export type MessageProps = Props;
export default Message;
