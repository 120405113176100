import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { StoreContext } from './export';

interface Props {
  items: SidebarItemProps[];
}

const Sidebar: React.FunctionComponent<Props> = (props) => {
  const storeContext = React.useContext(StoreContext);

  return (
    <>
      <aside id="main-sidebar">
        {storeContext.userContext.loginDto && (
          <ul>
            {props.items.map((item, index) => (
              <SidebarItem key={index} eventKey={index} {...item} />
            ))}
            <hr />
            <li>
              <NavLink exact to="/" isActive={() => false} onClick={handleLogoutClick}>
                <FontAwesomeIcon icon="sign-out-alt" fixedWidth /> <FormattedMessage tagName="span" id="logout" />
              </NavLink>
            </li>
          </ul>
        )}
      </aside>
      <div id="sidebar-backdrop" onClick={storeContext.sidebarContext.handleSidebarToggle} />
    </>
  );

  function handleLogoutClick() {
    storeContext.userContext.handleLogin(undefined);
  }
};

export interface SidebarItemProps {
  id: string;
  icon: IconProp;
  to: string;
  eventKey?: number;
}

function SidebarItem(props: SidebarItemProps) {
  const labelId = 'navbar.' + props.id;
  return (
    <li>
      <NavLink to={props.to} exact={props.to === '/'}>
        <FontAwesomeIcon icon={props.icon} fixedWidth /> <FormattedMessage tagName="span" id={labelId} />
      </NavLink>
    </li>
  );
}

export default Sidebar;
