import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosError, AxiosResponse } from 'axios';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { bs, Functions, Interfaces, SearchContext, StoreContext, SearchContextProps } from './export';
import Image from './Image';
import * as rest from './Rest';

interface Props {
  name: string;
  defaultValue?: string;
  showFileName?: boolean;
  showThumbnail?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  onUploadSuccess?: (value: string) => void;
}

const ImageUploader: React.FunctionComponent<Props> = (props) => {
  const [files, setFiles] = React.useState<File[]>();
  const [value, setValue] = React.useState<string>();

  React.useEffect(() => {
    setValue(props.defaultValue ?? '');
  }, [props.defaultValue]);

  const storeContext = React.useContext(StoreContext);
  const searchContext = React.useContext<SearchContextProps<Interfaces.ImageDto>>(SearchContext);
  const intl = useIntl();

  return (
    <>
      <div className="image-upload-form-group">
        <label className={`btn btn-success mb-0 ${props.disabled ? 'disabled' : ''}`}>
          <FontAwesomeIcon icon="file-upload" /> <FormattedMessage tagName="span" id="picker.trigger.pickFromDevice" />
          <bs.Form.Control
            type="file"
            onClick={handleClick}
            onChange={handleUpload}
            style={{ display: 'none' }}
            multiple={props.multiple}
            disabled={props.disabled}
          />
        </label>
        {props.showFileName &&
          (files ? (
            <span className="btn btn-link">{files.length > 1 ? files.length + 'file' : files[0].name}</span>
          ) : (
            <span className="btn btn-link">
              <FormattedMessage id="picker.trigger.pickFromDevice.empty" />
            </span>
          ))}
      </div>
      {props.showThumbnail && value && <Image adminSrc={value} fluid thumbnail />}
    </>
  );

  /*
    Questa funzione serve per fare in modo scatenare la onChange
    anche se seleziono lo stesso file della selezione precedente.
    (In pratica se seleziono 2 volte lo stesso file)
   */
  function handleClick(e: React.ChangeEvent<any>) {
    const target = e.target as HTMLInputElement;
    target.value = '';
  }

  function handleUpload(e: React.ChangeEvent<any>) {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const file = target.files[0] as File;

      const maxSize = 1000000000;

      const data = new FormData();
      data.append('idSelectedStoreGeoArea', storeContext.idStoreGeoArea.toString());
      data.append('maxSize', maxSize.toString());
      data.append('name', file.name);
      data.append('image', file);

      axios({
        data: data,
        headers: {
          interceptError: false,
        },
        method: 'post',
        url: rest.imageUpload(),
      })
        .then((response: AxiosResponse) => {
          setFiles([file]);
          setValue(response.data);
          if (props.onUploadSuccess !== undefined) {
            props.onUploadSuccess(response.data);
          }
          searchContext.handleUploadSuccess(response.data);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 406) {
            Functions.alert(
              Functions.translate(
                'picker.trigger.pickFromDevice.error.maxSize',
                { actualSize: file.size, maxSize: maxSize },
                intl,
              ),
            );
          } else {
            Functions.alertAxiosError(error);
          }
        });
    }
  }
};

ImageUploader.defaultProps = {
  multiple: false,
  showFileName: true,
  showThumbnail: true,
};

export default ImageUploader;
