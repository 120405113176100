import * as React from 'react';
import { bs } from './export';

interface Props {}

const ButtonPlain: React.FunctionComponent<Props> = (props) => {
  return (
    <bs.Button variant="link" className="btn-plain px-0">
      {props.children}
    </bs.Button>
  );
};

export default ButtonPlain;
