import 'core-js';
import $ from 'jquery';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import * as ReactDOM from 'react-dom';
import App from './App';
import * as Constants from './Constants';
import './css/style.scss';
import messagesEn from './translations/en.json';
import messagesIt from './translations/it.json';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/it');
}

const messages = {
  en: { ...messagesEn },
  it: { ...messagesIt },
};

ReactDOM.render(
  <b2x.Index app={App} messages={messages} projectConfig={Constants.projectConfig} supportedLanguages={['en', 'it']} />,
  document.getElementById('root') as HTMLElement,
);

$(document).ready(() => {
  //
});

// registerServiceWorker();
