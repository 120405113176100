import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBan,
  faBars,
  faBullhorn,
  faChartPie,
  faChevronDown,
  faChevronUp,
  faFile,
  faFileDownload,
  faFileExcel,
  faFileImage,
  faFileUpload,
  faImages,
  faListUl,
  faMinus,
  faPlus,
  faShoppingCart,
  faSignOutAlt,
  faStar,
  faTachometerAlt,
  faTrash,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import devextremeConfig from 'devextreme/core/config';
import { loadMessages as loadDevextremeMessages, locale as devextremeLocale } from 'devextreme/localization';
import itMessages from 'devextreme/localization/messages/it.json';
import $ from 'jquery';
import moment from 'moment';
import 'moment/locale/it';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import './css/style.scss';
import { Constants, Functions, Interfaces, Rest } from './export';
import messagesEnCommon from './translations/en.json';
import messagesItCommon from './translations/it.json';

devextremeConfig({
  defaultCurrency: 'EUR',
});

interface Props {
  app: React.FunctionComponent;
  messages: { [key: string]: {} };
  supportedLanguages: string[];
  projectConfig: Interfaces.ProjectConfig;
}

const formats = {
  date: {
    b2x: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  },
};

library.add(
  faPlus,
  faMinus,
  faStar,
  faFileImage,
  faSignOutAlt,
  faImages,
  faTachometerAlt,
  faShoppingCart,
  faListUl,
  faBullhorn,
  faChartPie,
  faWrench,
  faListUl,
  faBars,
  faFile,
  faFileExcel,
  faFileUpload,
  faFileDownload,
  faChevronUp,
  faChevronDown,
  faTrash,
  faBan,
);

export const StoreContext = React.createContext<StoreContextProps>({
  idStoreGeoArea: 0,
  indexingIsEnabled: false,
  localeContext: {
    handleLocaleChange: () => undefined,
    supportedLanguages: [],
  },
  sidebarContext: {
    handleSidebarToggle: () => undefined,
    isOpen: true,
  },
  userContext: {
    handleLogin: () => undefined,
  },
  cacheContext: {
    cache: {},
  },
});

const Index: React.FunctionComponent<Props> = (props) => {
  const [locale, setLocale] = React.useState<string>('it');
  const [loginDto, setLoginDto] = React.useState<Interfaces.LoginDto>();
  const [sidebarIsOpen, setSidebarIsOpen] = React.useState<boolean>(window.innerWidth > Constants.screenXSmax);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cache, setCache] = React.useState<Interfaces.Cache>({});
  const [indexingIsEnabled, setIndexingIsEnabled] = React.useState<boolean>(false);
  const [location, setLocation] = React.useState<Interfaces.Coordinates>();

  loadDevextremeMessages(itMessages);
  devextremeLocale(locale);
  moment.locale(locale);

  let ajaxLoadingCount = 0;

  React.useEffect(() => {
    customizeAxios(axios);
  }, []);

  const messages: { [key: string]: {} } = {
    en: { ...messagesEnCommon, ...props.messages.en },
    it: { ...messagesItCommon, ...props.messages.it },
  };

  const storeContext = {
    idStoreGeoArea: props.projectConfig.idStoreGeoArea,
    indexingIsEnabled: indexingIsEnabled,
    localeContext: {
      supportedLanguages: props.supportedLanguages,
      handleLocaleChange: setLocale,
    },
    sidebarContext: {
      isOpen: sidebarIsOpen,
      handleSidebarToggle: handleSidebarToggle,
    },
    userContext: {
      loginDto: loginDto,
      handleLogin: handleLogin,
      location: location,
    },
    cacheContext: {
      cache: cache,
    },
  };

  return (
    <IntlProvider locale={locale} defaultLocale="en" messages={messages[locale]} formats={formats}>
      <BrowserRouter>
        <StoreContext.Provider value={storeContext}>
          {loading && <div id="site-overlay" />}
          <div id="react-root" className={storeContext.sidebarContext.isOpen ? 'sidebar-open' : ''}>
            <props.app />
          </div>
        </StoreContext.Provider>
      </BrowserRouter>
    </IntlProvider>
  );

  function handleLogin(loginDtoParam?: Interfaces.LoginDto) {
    updateAxiosLoginHeaders(axios, loginDtoParam);
    if (loginDtoParam) {
      getLocation();
      Promise.all([indexingCheck(), initCache()]).then(() => {
        setLoginDto(loginDtoParam);
      });
    }
  }

  function initCache(): Promise<void[]> {
    let promises: Promise<void>[] = [];
    props.supportedLanguages.forEach((supportedLanguage) => {
      promises.push(
        axios({
          method: 'get',
          url: Rest.cacheGet(),
          params: {
            languageCode: supportedLanguage,
          },
        }).then((response: AxiosResponse) => {
          const cacheDto = response.data as Interfaces.CacheDto;
          setCache((prevState) => {
            return { ...prevState, [supportedLanguage]: cacheDto };
          });
        }),
      );
    });
    return Promise.all(promises);
  }

  async function indexingCheck(): Promise<void> {
    const response = await axios({
      method: 'get',
      url: Rest.indexingCheck(),
      params: {
        idSelectedStoreGeoArea: storeContext.idStoreGeoArea,
      },
    });
    const enabled = response.data as boolean;
    setIndexingIsEnabled(enabled);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      });
    }
  }

  function handleSidebarToggle() {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  function customizeAxios(axiosInternal: AxiosStatic) {
    axiosInternal.defaults.headers.interceptError = true;

    axiosInternal.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // Do something before request is sent
        config.headers.isoCode = 'it';
        waitOn();
        return config;
      },
      (error: AxiosError) => {
        // Do something with request error
        waitOff();
        return Promise.reject(error);
      },
    );
    axiosInternal.interceptors.response.use(
      (response: AxiosResponse) => {
        // Do something with response data
        waitOff();
        return response;
      },
      (error: AxiosError) => {
        // Do something with response error
        waitOff();
        if (error.response) {
          window.console.log(error.response.data);
          window.console.log(error.response);
        }
        window.console.log(error.config);
        if (error.config.headers.interceptError) {
          Functions.alertAxiosError(error);
        }
        return Promise.reject(error);
      },
    );
  }

  function updateAxiosLoginHeaders(axiosInternal: AxiosStatic, loginDtoParam?: Interfaces.LoginDto) {
    axiosInternal.interceptors.request.use((config: AxiosRequestConfig) => {
      if (loginDtoParam) {
        config.headers.devApiKey = loginDtoParam.sessionId;
        config.headers.jsessionid = loginDtoParam.sessionId;
      }
      return config;
    });
  }

  function waitOn() {
    if (ajaxLoadingCount === 0) {
      setLoading(true);
    }
    // setAjaxLoadingCount(ajaxLoadingCount + 1);
    ajaxLoadingCount++;
  }

  function waitOff() {
    if (ajaxLoadingCount <= 1) {
      setLoading(false);
    }
    // setAjaxLoadingCount(ajaxLoadingCount - 1);
    ajaxLoadingCount--;
  }
};

$(document).ready(() => {
  //
});

export interface StoreContextProps {
  idStoreGeoArea: number;
  indexingIsEnabled: boolean;
  localeContext: {
    handleLocaleChange: (locale: string) => void;
    supportedLanguages: string[];
  };
  sidebarContext: {
    isOpen: boolean;
    handleSidebarToggle: () => void;
  };
  userContext: {
    loginDto?: Interfaces.LoginDto;
    handleLogin: (loginDto?: Interfaces.LoginDto) => void;
    location?: Interfaces.Coordinates;
  };
  cacheContext: {
    cache: Interfaces.Cache;
  };
}

export default Index;
