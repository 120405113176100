import { useFormikContext } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Card from './Card';
import { Functions, Interfaces, bs } from './export';

interface Props {
  title?: Interfaces.FormattedMessageProps;
  submitLabel: Interfaces.FormattedMessageProps;
  showResetButton?: boolean;
}

const FormCard: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const formik = useFormikContext();

  return (
    <>
      <Card
        title={props.title}
        footer={
          <bs.ButtonToolbar>
            {props.showResetButton && (
              <bs.Button type="reset" variant="light" className="ml-2">
                <FormattedMessage id="filtersForm.reset" />
              </bs.Button>
            )}
            <bs.Button
              className="ml-2"
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting || !Functions.isFormValid(formik.errors)}
            >
              <FormattedMessage {...props.submitLabel} />
            </bs.Button>
          </bs.ButtonToolbar>
        }
      >
        {props.children}
      </Card>
      {!Functions.isFormValid(formik.errors) && (
        <bs.Alert variant="danger">{Functions.printErrors(formik.errors)}</bs.Alert>
      )}
    </>
  );
};

FormCard.defaultProps = {
  showResetButton: false,
};

export default FormCard;
