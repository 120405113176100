import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonPlain from './ButtonPlain';
import { bs, Card, de, Interfaces } from './export';
import PageHeader from './PageHeader';
import { PickerContext } from './Picker';

interface Props<Dto> {
  title: React.ReactElement;
  filters: React.ReactElement;
  selectionActions: Interfaces.ResultsSelectionActionProps<Dto>[];
  actions: (searchContext: SearchContextProps<Dto>) => React.ReactElement[];
  table: any;
}

export const SearchContext = React.createContext<SearchContextProps<any>>({
  dtos: [],
  handleDataGridSelectionChange: () => undefined,
  handleSearch: () => undefined,
  handleUploadSuccess: () => undefined,
  refresh: () => undefined,
  selectedIds: [],
  selectedRows: [],
  selectedDtos: [],
});

export interface SearchContextProps<Dto> {
  dtos: Dto[];
  handleSearch: (dtos: Dto[]) => void;
  selectedIds: string[];
  selectedRows: de.DataGridRow<Dto>[];
  selectedDtos: Dto[];
  handleDataGridSelectionChange: ({
    selectedRowKeys,
    selectedRowsData,
  }: {
    selectedRowKeys?: string[] | undefined;
    selectedRowsData?: de.DataGridRow<Dto>[] | undefined;
  }) => void;
  handleUploadSuccess: (value?: string) => void;
  refresh: () => void;
}

const SearchPage = <Dto extends object>(props: React.PropsWithChildren<Props<Dto>>) => {
  const [dtos, setDtos] = React.useState<Dto[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<de.DataGridRow<Dto>[]>([]);
  const [selectedDtos, setSelectedDtos] = React.useState<Dto[]>([]);
  const [key, setKey] = React.useState<number>(1);

  const pickerContext = React.useContext(PickerContext);

  const searchContext = {
    dtos: dtos,
    handleDataGridSelectionChange: handleDataGridSelectionChange,
    handleSearch: handleSearch,
    handleUploadSuccess: handleUploadSuccess,
    refresh: refresh,
    selectedIds: selectedIds,
    selectedRows: selectedRows,
    selectedDtos: selectedDtos,
  };

  return (
    <SearchContext.Provider value={searchContext} key={key}>
      <div className="mb-n3">
        {!pickerContext.insidePicker && <PageHeader>{props.title}</PageHeader>}
        <bs.Row>
          <bs.Col md={3} lg={2}>
            {props.filters}
          </bs.Col>
          <bs.Col md={9} lg={10}>
            <Card
              title={
                searchContext.dtos
                  ? {
                      id: 'searchResults.titleWithResults',
                      values: {
                        from: 1,
                        to: searchContext.dtos.length,
                        total: searchContext.dtos.length,
                      },
                    }
                  : {
                      id: 'searchResults.titleWithoutResults',
                    }
              }
              table={props.table}
            >
              <div className="d-flex justify-content-between">
                <bs.ButtonToolbar>
                  {props.selectionActions.map((selectionAction, index) =>
                    selectionAction.action ? (
                      <bs.Button
                        className="mr-2"
                        key={index}
                        disabled={
                          searchContext.selectedIds.length === 0 ||
                          (selectionAction.max !== undefined &&
                            searchContext.selectedIds.length > selectionAction.max) ||
                          (selectionAction.disabledCheck !== undefined &&
                            selectionAction.disabledCheck(searchContext) !== undefined)
                        }
                        variant="light"
                        onClick={() => selectionAction.action!(searchContext)}
                        title={
                          selectionAction.disabledCheck !== undefined
                            ? selectionAction.disabledCheck(searchContext)
                            : undefined
                        }
                      >
                        <FormattedMessage id={selectionAction.labelId} />
                      </bs.Button>
                    ) : selectionAction.children ? (
                      <bs.Dropdown className="mr-2" key={index}>
                        <bs.Dropdown.Toggle
                          id="test"
                          disabled={
                            searchContext.selectedIds.length === 0 ||
                            (selectionAction.max !== undefined &&
                              searchContext.selectedIds.length > selectionAction.max) ||
                            (selectionAction.disabledCheck !== undefined &&
                              selectionAction.disabledCheck(searchContext) !== undefined)
                          }
                          variant="light"
                          title={
                            selectionAction.disabledCheck !== undefined
                              ? selectionAction.disabledCheck(searchContext)
                              : undefined
                          }
                        >
                          <FormattedMessage id={selectionAction.labelId} />
                        </bs.Dropdown.Toggle>
                        <bs.Dropdown.Menu>
                          {selectionAction.children.map((innerSelectionAction, index) => (
                            <bs.Dropdown.Item
                              key={index}
                              disabled={
                                searchContext.selectedIds.length === 0 ||
                                (innerSelectionAction.max !== undefined &&
                                  searchContext.selectedIds.length > innerSelectionAction.max) ||
                                (innerSelectionAction.disabledCheck !== undefined &&
                                  innerSelectionAction.disabledCheck(searchContext) !== undefined)
                              }
                              onClick={() => innerSelectionAction.action!(searchContext)}
                              title={
                                innerSelectionAction.disabledCheck !== undefined
                                  ? innerSelectionAction.disabledCheck(searchContext)
                                  : undefined
                              }
                            >
                              <FormattedMessage id={innerSelectionAction.labelId} />
                            </bs.Dropdown.Item>
                          ))}
                        </bs.Dropdown.Menu>
                      </bs.Dropdown>
                    ) : (
                      <></>
                    ),
                  )}
                  <ButtonPlain>
                    <FormattedMessage
                      id="searchPage.selectionActions.selectedItemsNumber"
                      values={{ selected: searchContext.selectedIds.length }}
                    />
                  </ButtonPlain>
                </bs.ButtonToolbar>
                <bs.ButtonToolbar>
                  {props.actions(searchContext).map((action, index) => React.cloneElement(action, { key: index }))}
                </bs.ButtonToolbar>
              </div>
            </Card>
          </bs.Col>
        </bs.Row>
      </div>
    </SearchContext.Provider>
  );

  function handleDataGridSelectionChange({
    selectedRowKeys,
    selectedRowsData,
  }: {
    selectedRowKeys?: string[] | undefined;
    selectedRowsData?: de.DataGridRow<Dto>[] | undefined;
  }) {
    if (!selectedRowKeys) {
      selectedRowKeys = [];
    }
    if (!selectedRowsData) {
      selectedRowsData = [];
    }
    setSelectedIds(selectedRowKeys);
    setSelectedRows(selectedRowsData);
    setSelectedDtos(selectedRowsData.map((row) => row.dto));
    pickerContext.handleSelectionChanged(selectedRowKeys);
  }

  function handleSearch(dtosParam: Dto[]) {
    setDtos(dtosParam);
  }

  function handleUploadSuccess(value?: string) {
    if (pickerContext.insidePicker && value) {
      pickerContext.handleUploadSuccess(value);
    } else {
      refresh();
    }
  }

  function refresh() {
    setDtos([]);
    setSelectedIds([]);
    setSelectedRows([]);
    setKey(key + 1);
  }
};

export default SearchPage;
