import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ImagesPage from './ImagesPage';

interface Props {}

const ImagesRouter: React.FunctionComponent<Props> = (props) => {

  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact component={ImagesPage} />
    </Switch>
  );
};

export default ImagesRouter;
