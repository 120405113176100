import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { bs, CreateUpdateCardContext, Functions, ImagePicker } from './export';
import FilePicker from './FilePicker';
import FileUploader from './FileUploader';
import ImageUploader from './ImageUploader';
import Message, { MessageProps } from './Message';

interface Props {
  name: string;
  type?: TypeName;
  label?: MessageProps;
  fixedValidationMessage?: string;
  selectOptions?: [string, string][];
  noMandatoryStyling?: boolean;
  validationSchema?: Yup.MixedSchema;
  help?: MessageProps;
  disabled?: boolean;
  tags?: [string, string][];
}

type TypeName =
  | 'hidden'
  | 'checkbox'
  | 'date'
  | 'email'
  | 'file'
  | 'image-picker'
  | 'image-uploader'
  | 'file-picker'
  | 'file-uploader'
  | 'password-current'
  | 'password-new'
  | 'select'
  | 'text'
  | 'textarea'
  | 'wysiwyg'
  | 'number'
  | 'empty'
  | 'tags';

const FormGroup: React.FunctionComponent<
  Props & {
    field: FieldInputProps<any>;
    meta: FieldMetaProps<any>;
    helpers: FieldHelperProps<any>;
    editEnabled: boolean;
  }
> = (props) => {
  let formControl: any;

  let typeString: string;
  switch (props.type) {
    case 'password-current': {
      typeString = 'password';
      break;
    }
    case 'password-new': {
      typeString = 'password';
      break;
    }
    default: {
      typeString = props.type!;
      break;
    }
  }

  let autoComplete: string | undefined;
  switch (props.type) {
    case 'password-current': {
      autoComplete = 'current-password';
      break;
    }
    case 'password-new': {
      autoComplete = 'new-password';
      break;
    }
    case 'email': {
      autoComplete = 'username';
      break;
    }
  }

  switch (props.type) {
    case 'wysiwyg': {
      formControl = (
        <div className={props.editEnabled ? '' : 'wysiwyg-disabled'}>
          <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            initialValue={convertValueToString(props.field.value)}
            init={{
              content_style: 'body {font-family: sans-serif; color: #333333; font-size: 13px;}',
              height: 300,
              menubar: false,
              paste_as_text: true,
              plugins: ['link', 'image', 'code', 'fullscreen', 'paste', 'wordcount', 'lists', 'table'],
              skin_url: '/tinymce/skins/ui/b2x',
              toolbar:
                'undo redo | bold italic underline strikethrough | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | forecolor backcolor | code | fullscreen',
              color_map: [
                '8DC379',
                'Green',
                'FB837B',
                'Red',
                'F8B953',
                'Yellow',
                '74C4EC',
                'Blue light',
                '004C8B',
                'Blue dark',
                'F17330',
                'Orange light',
                'EE5808',
                'Orange dark',
                'DDB22A',
                'Gold',
                'ffffff',
                'White',
                'e1e1e1',
                'Grey',
                '000000',
                'Black',
              ],
            }}
            textareaName={props.field.name}
            disabled={!props.editEnabled}
            onChange={handleEditorChange}
          />
        </div>
      );
      break;
    }
    case 'checkbox': {
      let checked = false;
      if (props.field.value !== '') {
        checked = props.field.value as boolean;
      }
      formControl = (
        <bs.Form.Check
          type="checkbox"
          name={props.field.name}
          checked={checked}
          autoComplete={autoComplete}
          onChange={props.field.onChange}
          onBlur={props.field.onBlur}
          disabled={props.disabled}
          label={<Message {...props.label} />}
        />
      );
      break;
    }
    case 'file': {
      formControl = (
        <div className="image-upload-form-group">
          <label className="btn btn-success mb-0">
            <FontAwesomeIcon icon="file-upload" />{' '}
            <FormattedMessage tagName="span" id="picker.trigger.pickFromDevice" />
            <bs.Form.Control
              name={props.field.name}
              type={typeString}
              value={convertValueToString(props.field.value)}
              style={{ display: 'none' }}
              autoComplete={autoComplete}
              onChange={props.field.onChange}
              onBlur={props.field.onBlur}
              disabled={props.disabled}
              isValid={isValid()}
              isInvalid={isInvalid()}
            />
          </label>
          <span className="btn btn-link">
            <FormattedMessage id="picker.trigger.pickFromDevice.empty" />
          </span>
        </div>
      );
      break;
    }
    case 'image-picker': {
      formControl = (
        <ImagePicker
          name={props.field.name}
          defaultSelection={props.field.value ? convertValueToString(props.field.value) : undefined}
          onPick={props.helpers.setValue}
          disabled={props.disabled}
        />
      );
      break;
    }
    case 'image-uploader': {
      formControl = (
        <ImageUploader
          name={props.field.name}
          defaultValue={convertValueToString(props.field.value)}
          onUploadSuccess={props.helpers.setValue}
          disabled={props.disabled}
        />
      );
      break;
    }
    case 'file-picker': {
      formControl = (
        <FilePicker
          name={props.field.name}
          defaultSelection={props.field.value ? convertValueToString(props.field.value) : undefined}
          onPick={props.helpers.setValue}
          disabled={props.disabled}
        />
      );
      break;
    }
    case 'file-uploader': {
      formControl = (
        <FileUploader
          name={props.field.name}
          defaultValue={convertValueToString(props.field.value)}
          onUploadSuccess={props.helpers.setValue}
          disabled={props.disabled}
        />
      );
      break;
    }
    case 'select': {
      formControl = (
        <bs.Form.Control
          name={props.field.name}
          type={typeString}
          value={convertValueToString(props.field.value)}
          as="select"
          autoComplete={autoComplete}
          onChange={props.field.onChange}
          onBlur={props.field.onBlur}
          disabled={props.disabled}
          isValid={isValid()}
          isInvalid={isInvalid()}
        >
          <option key="" value="" />
          {props.selectOptions?.map(([optionValue, optionLabel]) => (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          ))}
        </bs.Form.Control>
      );
      break;
    }
    case 'tags': {
      formControl = props.tags?.map(([tagValue, tagLabel]) => (
        <bs.Form.Check
          key={tagValue}
          inline
          label={tagLabel}
          type="checkbox"
          value={tagValue}
          checked={isTagActive(props.field.value, tagValue)}
          onChange={handleTagChange}
        />
      ));
      break;
    }
    case 'empty': {
      formControl = undefined;
      break;
    }
    default: {
      let componentClass: React.ReactType;
      switch (props.type) {
        case 'textarea': {
          componentClass = 'textarea';
          break;
        }
        default: {
          componentClass = 'input';
          break;
        }
      }
      formControl = (
        <bs.Form.Control
          name={props.field.name}
          type={typeString}
          value={convertValueToString(props.field.value)}
          as={componentClass}
          autoComplete={autoComplete}
          onChange={props.field.onChange}
          onBlur={props.field.onBlur}
          disabled={props.disabled}
          isValid={isValid()}
          isInvalid={isInvalid()}
        />
      );
      break;
    }
  }
  let className = '';
  if (props.validationSchema !== undefined) {
    if (!props.noMandatoryStyling && Functions.hasTest(props.field.name, props.validationSchema, 'required')) {
      className += 'mandatory ';
    }
  }
  if (props.type === 'hidden') {
    className += 'hidden ';
  }
  if (props.type === 'empty') {
    className += 'empty ';
  }

  // if (props.formContext.horizontal) {
  //   return (
  //     <FormGroup
  //       controlId={props.field.name}
  //       // validationState={state.validationState}
  //       bsSize={props.bsSize}
  //       className={className}
  //     >
  //       <Col componentClass={Form.Label} sm={2}>
  //         {props.label && <FormattedMessage id={props.label} />}
  //       </Col>
  //       <Col sm={10}>
  //         {formControl}
  //         {props.children}
  // tslint:disable-next-line:max-line-length
  //         {props.type !== 'select' && props.type !== 'textarea' && props.type !== 'wysiwyg' && <FormControl.Feedback />}
  //       </Col>
  //     </FormGroup>
  //   );
  // } else {
  return (
    <bs.Form.Group controlId={props.field.name} className={className}>
      {props.label && (
        <bs.Form.Label>
          <span>
            <Message {...props.label} />
          </span>
        </bs.Form.Label>
      )}
      {formControl}
      <bs.Form.Control.Feedback />
      {props.help && (props.help.message || props.help.formattedMessage) && (
        <bs.Form.Text className="text-muted">
          <Message {...props.help} />
        </bs.Form.Text>
      )}
      {props.children}
      {props.type !== 'select' && props.type !== 'textarea' && props.type !== 'wysiwyg' && <bs.Form.Control.Feedback />}
    </bs.Form.Group>
  );
  // }

  function convertValueToString(valueObj: string | number | Date | boolean | undefined): string {
    let stringValue = '';
    if (!valueObj) {
      return stringValue;
    }
    switch (props.type) {
      case 'date': {
        stringValue = Functions.formatDateForInput(valueObj as Date);
        break;
      }
      default: {
        stringValue = valueObj.toString();
        break;
      }
    }
    return stringValue;
  }

  function isValid(): boolean {
    return props.meta.touched && !props.meta.error;
  }

  function isInvalid(): boolean {
    return !!props.meta.error;
  }

  function handleEditorChange(e: any) {
    const content = e.target.getContent();
    props.helpers.setValue(content);
  }

  function isTagActive(tags: string[], tag: string): boolean {
    if (!tags) {
      tags = [];
    }
    return tags.includes(tag);
  }

  function handleTagChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const checked = e.target.checked;
    const tags = props.field.value ?? ([] as string[]);
    const indexOfThisValue = tags.indexOf(value);
    if (checked && indexOfThisValue === -1) {
      tags.push(value);
    } else if (!checked && indexOfThisValue !== -1) {
      tags.splice(indexOfThisValue, 1);
    }
    props.helpers.setValue(tags);
  }
};

FormGroup.defaultProps = {
  type: 'text',
};

const MemoizedFormGroup: React.FunctionComponent<Props> = (props) => {
  const createUpdateCardContext = React.useContext(CreateUpdateCardContext);
  const [field, meta, helpers] = useField(props.name);
  const memoizedValue = React.useMemo(() => {
    return (
      <FormGroup
        {...props}
        field={field}
        meta={meta}
        helpers={helpers}
        editEnabled={createUpdateCardContext.editEnabled}
      />
    );
  }, [JSON.stringify(props), JSON.stringify(field), JSON.stringify(meta), JSON.stringify(createUpdateCardContext)]);
  return memoizedValue;
};

export default MemoizedFormGroup;
