import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import * as b2x from 'react-b2x/src/export';
import { StoreContext } from 'react-b2x/src/export';
import { FormattedMessage } from 'react-intl';
import HomePageManagementForm from './HomePageManagementForm';

interface Props {}

const HomePageManagementPage: React.FunctionComponent<Props> = (props) => {
  const [content, setContent] = React.useState<b2x.Interfaces.ContentDto>();
  const storeContext = React.useContext(StoreContext);

  React.useEffect(() => {
    axios({
      method: 'get',
      params: {
        codeLanguage: 'it',
        idContent: '30689',
        idSelectedStoreGeoArea: storeContext.idStoreGeoArea,
      },
      url: b2x.Rest.contentGet(),
    }).then((response: AxiosResponse) => {
      setContent(response.data as b2x.Interfaces.ContentDto);
    });
  }, [storeContext.idStoreGeoArea]);

  return (
    <>
      <b2x.PageHeader>
        <FormattedMessage id="homePage.homepageManagement.title" />
      </b2x.PageHeader>
      {content && <HomePageManagementForm content={content} />}
    </>
  );
};

export default HomePageManagementPage;
