import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Interfaces } from './export';
import FilesFiltersForm from './FilesFiltersForm';
import FilesGrid from './FilesGrid';
import FileUploader from './FileUploader';
import SearchPage from './SearchPage';

interface Props {}

const FilesPage: React.FunctionComponent<Props> = (props) => {
  return (
    <SearchPage<Interfaces.FileDto>
      title={<FormattedMessage id="filesPage.title" />}
      filters={<FilesFiltersForm />}
      selectionActions={[
        {
          action: () => undefined,
          labelId: 'filesPage.results.action1.label',
        },
        {
          action: () => undefined,
          labelId: 'filesPage.results.action2.label',
        },
      ]}
      actions={(searchContext) => [<FileUploader key="1" name="" showFileName={false} />]}
      table={<FilesGrid />}
    />
  );
};

export default FilesPage;
