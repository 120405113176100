import * as React from 'react';
import { useIntl } from 'react-intl';

interface Props<T> {
  name: T;
}

const Example = <T extends object>(props: React.PropsWithChildren<Props<T>>) => {
  const [count, setCount] = React.useState<number>(0);
  const intl = useIntl();

  return (
    <p onClick={handleButtonClick}>
      Hello {props.name}! You clicked me {count} times!
    </p>
  );

  function handleButtonClick() {
    setCount(count + 1);
  }
};

Example.defaultProps = {};

export default Example;
// export default React.memo(Example, Functions.logChangedProps);
